import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { history, store } from "./store";
import App from "./components/App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const RootApp = () => (
  <Provider store={store}>
    <Router history={history}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Router>
  </Provider>
);
ReactDOM.render(<RootApp />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
