import { createReducer } from "reduxsauce";
import actions from "./actions";

const { Types } = actions;
const INITIAL_STATE = {
  notifications: [],
};

const enqueueSnackbar = (state, { notification }) => {
  const key = notification.options && notification.options.key;

  return {
    ...state,
    notifications: [
      ...state.notifications,
      {
        key: key || new Date().getTime() + Math.random(),
        ...notification,
      },
    ],
  };
};

const closeSnackbar = (state, { key }) => {
  const dismissAll = !key;
  return {
    ...state,
    notifications: state.notifications.map((notification) =>
      dismissAll || notification.key === key
        ? { ...notification, dismissed: true }
        : { ...notification }
    ),
  };
};

const removeSnackbar = (state, { key }) => ({
  ...state,
  notifications: state.notifications.filter(
    (notification) => notification.key !== key
  ),
});

export const HANDLERS = {
  [Types.ENQUEUE_SNACKBAR]: enqueueSnackbar,
  [Types.CLOSE_SNACKBAR]: closeSnackbar,
  [Types.REMOVE_SNACKBAR]: removeSnackbar,
};

export default createReducer(INITIAL_STATE, HANDLERS);
