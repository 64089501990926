import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { ethers, formatUnits } from "ethers";
import { Button, Input, sharedStyles, Text } from "../shared";
import { MESSAGES, ROUTES, LOCK_OPTIONS } from "../../enums";
import InvestorsAreaLayout from "../../layouts/InvestorsAreaLayout";
import useModal from "../../hooks/useModal";
import Modal from "../shared/modal";
import { getExpectedTokens } from "../../store/liquidity/sagas";
import styles from "./style.module.css";

var sqrt = require('bigint-isqrt');
const INPUT_NAME = "withdrawAmount";
const _ether: number = 10 ** 18;


function throwError(_in: any): never {
  throw new Error("An error occurred");
}

export const InvestorsArea = ({
  liquidities,
  isLiquidityLoading,
  isStakingLoading,
  getLiquidities,
  retrieveLiquidity,
  blxmDecimals,
  tokenDecimals,
  provider,
  getStaking,
  staking,
  retrieveStaking,
  reStake,
  enqueueSnackbar,
  activeWallet,
  signer,
  getCalculationsData,
  getCalculationsDataForStaking,
  APRs,
} :
{
  liquidities: any,
  isLiquidityLoading: any,
  isStakingLoading: any,
  getLiquidities: any,
  retrieveLiquidity: any,
  blxmDecimals: any,
  tokenDecimals: any,
  provider: any,
  getStaking: any,
  staking: any,
  retrieveStaking: any,
  reStake: any,
  enqueueSnackbar: any,
  activeWallet: any,
  signer: any,
  getCalculationsData: any,
  getCalculationsDataForStaking: any,
  APRs: any,

}) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useModal(false);
  const {
    isOpen: isReStakeModalOpen,
    onOpen: onReStakeModalOpen,
    onClose: onReStakeModalClose,
  } = useModal(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [maxWithdrawAmount, setMaxWithdrawAmount] = useState(null);
  const [expectedBlxm, setExpectedBlxm] = useState(null);
  const [expectedExternal, setExpectedExternal] = useState(null);
  const [isLoadingExpected, setIsLoadingExpected] = useState(false);
  const [chosenPeriodIndex, setChosenPeriodIndex] = useState(3);

  const { register, handleSubmit, control, setValue, watch } = useForm({
    [INPUT_NAME]: null,
  });

  const getLiquiditiesReq = useCallback(() => {
    if (signer) {
      getLiquidities(activeWallet, signer, provider);
    }
  }, [activeWallet, getLiquidities, provider, signer]);

  const getStakingReq = useCallback(() => {
    if (signer) {
      getStaking(activeWallet, signer, provider);
    }
  }, [activeWallet, getStaking, provider, signer]);

  const getData = useCallback(() => {
    getLiquiditiesReq();
    getStakingReq();
  }, [getLiquiditiesReq, getStakingReq]);

  useEffect(() => {
    getData();
    if (provider && signer)
      getCalculationsDataForStaking(activeWallet, provider, signer);
  }, [activeWallet, getCalculationsDataForStaking, getData, provider, signer]);

  useEffect(() => {
    setValue("lockDaysNumber", 360);
  }, [setValue]);

  const onLockOptionClick = (value: any, index: any) => {
    setValue("lockDaysNumber", value);
    setChosenPeriodIndex(index);
  };

  const handleCloseModal = () => {
    onClose();
    setSelectedItem(null);
    setValue(INPUT_NAME, 0, {
      shouldValidate: true,
    });
    setMaxWithdrawAmount(null);
    setExpectedBlxm(null);
    setExpectedExternal(null);
  };

  const handleReStakeCloseModal = () => {
    onReStakeModalClose();
    setValue("lockDaysNumber", 360);
    setSelectedItem(null);
  };

  const showLoadingError = (type?: any) => {
    enqueueSnackbar({
      message: MESSAGES.transactionPending,
      options: { variant: "error" },
    });
  };

  const retrieveLiquiditySuccessCallback = (url: any) => {
    if (url) {
      enqueueSnackbar({
        message: MESSAGES.transactionSuccess,
        options: {
          variant: "success",
          // eslint-disable-next-line react/no-unstable-nested-components
          action: () => (
            <a
              href={url}
              className={styles.transactionLink}
              target="_blank"
              rel="noreferrer"
            >
              Go to transaction
            </a>
          ),
        },
      });
    }

    getLiquiditiesReq();
  };

  const retrieveStakingSuccessCallback = ( url:any ) => {
    if (url) {
      enqueueSnackbar({
        message: MESSAGES.transactionSuccess,
        options: {
          variant: "success",
          // eslint-disable-next-line react/no-unstable-nested-components
          action: () => (
            <a
              href={url}
              className={styles.transactionLink}
              target="_blank"
              rel="noreferrer"
            >
              Go to transaction
            </a>
          ),
        },
      });
    }

    getStakingReq();
  };

  const handleRetrieveLiquidity = () => {
    if (isLiquidityLoading) {
      showLoadingError({ type: "liquidity" });
      return;
    }

    const value =
      Number.isNaN(watch(INPUT_NAME)) ||
      watch(INPUT_NAME) == null ||
      !watch(INPUT_NAME)
        ? 0
        : String(watch(INPUT_NAME));

    
    if ( selectedItem !== null ) {
      if (
        Object.keys(selectedItem!).includes("isLocked")
          &&
        Object.keys(selectedItem!).includes("position")
      ) 
      {
        const { isLocked, position: index } = selectedItem;
        if (isLocked)
        enqueueSnackbar({
          message: MESSAGES.withdrawLockedTokens,
          options: { variant: "warning" },
        });

        console.log("calling RETRIEVE LIQUIDITY value %s activeWallet %s provider %s", value, activeWallet, provider);
        retrieveLiquidity(
          value,
          index,
          activeWallet,
          provider,
          retrieveLiquiditySuccessCallback
        );
      }
    }
  };

  const handleReStake = () => {

    if ( ( selectedItem !== null ) && ( selectedItem !== 'undefined' ) ) {
      if ( 
        Object.keys(selectedItem).includes("position") 
        &&
        Object.keys(selectedItem).includes("amount") 
        &&
        Object.keys(selectedItem).includes("rewardAmount") 
      ) 
      {
        const { position: index } = selectedItem;

        // const { position: index } = selectedItem!;
        const lockDays = watch("lockDaysNumber");

        var amount, rewardAmount: string;
        if ( typeof selectedItem !== "undefined" ) {
          let _selectedItem : any = selectedItem;
          amount = String(BigInt(_selectedItem.amount));
          rewardAmount = String(BigInt(_selectedItem.rewardAmount));

          reStake(
            amount,
            rewardAmount,
            activeWallet,
            index,
            lockDays,
            provider,
            retrieveStakingSuccessCallback
          );
        }
      }
    }
  };

  const handleRetrieveStaking = () => {
    if (isStakingLoading) {
      showLoadingError({ type: "stake" });
      return;
    }

    const value : any =
      Number.isNaN(watch(INPUT_NAME)) ||
      watch(INPUT_NAME) == null ||
      !watch(INPUT_NAME)
        ? 0
        : String(watch(INPUT_NAME));

    const { isLocked, position: index } = selectedItem!;

    if (isLocked)
      enqueueSnackbar({
        message: MESSAGES.withdrawLockedTokens,
        options: { variant: "warning" },
      });
    retrieveStaking(
      value,
      index,
      activeWallet,
      provider,
      retrieveStakingSuccessCallback
    );
  };

  const ether : number = 10 ** 18;
  const blxmDecimalsBN : number = 10 ** blxmDecimals;
  const tokenDecimalsBN : number = 10 ** tokenDecimals;

  const itemType = useMemo(() => {
    if (selectedItem) {
      return Object.keys(selectedItem).includes("liquidity")
        ? "liquidity"
        : "staking";
    }

    return null;
  }, [selectedItem]);

  const handleWithdrawClick = (item: any) => {
    if (isStakingLoading || isLiquidityLoading) {
      showLoadingError();
      return;
    }

    setSelectedItem(item);
    let n = "0";
    if (Object.keys(item).includes("liquidity")) {
      n = Number((
            ( Number(item.liquidity) * blxmDecimalsBN )
            / 
            ( Math.sqrt( Number(blxmDecimalsBN) * Number(tokenDecimalsBN) ) )
      )).toLocaleString('fullwide', {useGrouping:false})
    } else {
      n = Number(item.amount).toLocaleString('fullwide', {useGrouping:false});
    }

    setMaxWithdrawAmount(n);
    onOpen();
  };

  const handleReStakeClick = (item) => {
    if (isStakingLoading || isLiquidityLoading) {
      showLoadingError();
      return;
    }

    setSelectedItem(item);
    onReStakeModalOpen();
  };

  const handleAmountChange = (event) => {
    const value = event.target.value.replace(/[^0-9_.]/g, "");

    setValue(INPUT_NAME, value, {
      shouldValidate: true,
    });
    setExpectedBlxm(null);
    setExpectedExternal(null);
  };

  const onWithdraw = () => {
    if (itemType === "liquidity") {
      handleRetrieveLiquidity();
    } else if (itemType === "staking") {
      handleRetrieveStaking();
    }

    handleCloseModal();
  };

  const onReStake = () => {
    handleReStake();

    handleReStakeCloseModal();
  };

  const onCalculateExpectedTokens = async () => {
    setIsLoadingExpected(true);
    let value : number =
      Number.isNaN(watch(INPUT_NAME)) ||
      watch(INPUT_NAME) == null ||
      !watch(INPUT_NAME)
        ? 0
        : watch(INPUT_NAME);
    value = value * _ether;
    const { blxmAmountBN, tokenAmountBN } = await getExpectedTokens({
      liquidity: value,
      activeWallet,
      provider,
    });

    let _blxmAmountNumber : number = Number(String(blxmAmountBN));
    _blxmAmountNumber = _blxmAmountNumber / _ether;
    setExpectedBlxm(parseFloat(String(_blxmAmountNumber)).toFixed(5));
    let _tokenAmountBN : number = Number(String(tokenAmountBN));
    _tokenAmountBN = _tokenAmountBN / _ether;
    setExpectedExternal(parseFloat(String(_tokenAmountBN)).toFixed(5));

    setIsLoadingExpected(false);
  };

  useEffect(() => {
    if (provider) {
      getCalculationsData(activeWallet, provider);
    }
  }, [activeWallet, getCalculationsData, provider]);

  function truncateDecimals (num: number, digits: number) : string {
    if (num == 0) return ("0.000");
    var numS : string = num.toString(),
        decPos : number = numS.indexOf('.'),
        substrLength : number = decPos == -1 ? numS.length : 1 + decPos + digits,
        trimmedResult : string = numS.slice(0, substrLength),
        finalResult : string= isNaN(Number(trimmedResult)) ? parseFloat("0").toFixed(digits) : trimmedResult;
    // console.log("truncateDecimals returning %s - %s", finalResult, String(finalResult));
    return String(finalResult);
}
  const setMaxValue = () => {
    let maxValue : string;
    if ( (selectedItem !== null) && ( typeof selectedItem != "undefined" ) ) {
      /*
      if (itemType === "liquidity") {
        maxValue = BigNumber(selectedItem.liquidity.toString())
          .multipliedBy(
            blxmDecimalsBN.dividedBy(
              blxmDecimalsBN.multipliedBy(tokenDecimalsBN).squareRoot()
            )
          )
          .dividedBy(10 ** 18)
          .toString();
      } else if (itemType === "staking") {
        maxValue = BigNumber(selectedItem.amount.toString())
          .dividedBy(10 ** 18)
          .toString();
      }
      */
      let _ether : number = 10 ** 18;
      let _blxmDecimalsBN : number = _ether;
      let _tokenDecimalsBN : number = 10 ** tokenDecimals;

      if (itemType === "liquidity") {
        console.log("selectedItem.liquidity is %s", selectedItem.liquidity);
        // parseFloat(String(blxmAmountBN)).toFixed(5)
        let _maxValue : number =  
            Number(selectedItem.liquidity)
            / ( Math.sqrt( _blxmDecimalsBN * _tokenDecimalsBN ) ); 

        console.log("_maxValue is %s", _maxValue);
        maxValue = truncateDecimals(_maxValue,3);
        console.log("liquidity maxValue %s", maxValue);
      } else if (itemType === "staking") {
        maxValue = truncateDecimals( Number(selectedItem.amount) / _ether, 3 );
      }
    }
    setValue(INPUT_NAME, maxValue, {
      shouldValidate: true,
    });
    setExpectedBlxm(null);
    setExpectedExternal(null);
  };

  return (
    <InvestorsAreaLayout>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <form
          onSubmit={handleSubmit(onWithdraw)}
          className={sharedStyles.column}
        >
          <Text tag="p" type="medium">
            Available {itemType || "amount"} to withdraw:
          </Text>
          <Text tag="p" type="medium">
            {Number(formatUnits(String(maxWithdrawAmount === null ? 0 : maxWithdrawAmount), 18)).toFixed(5) || ""}
          </Text>
          <div className={sharedStyles.spacer} />
          <Input
            {...register(INPUT_NAME, {
              required: true,
            })}
            control={control}
            placeholder="Amount"
            onChange={handleAmountChange}
            className={styles.input}
            rightChild={
              <div className={styles.rightChild}>
                <Button onClick={setMaxValue} className={styles.inputButton}>
                  MAX
                </Button>
              </div>
            }
          />
          <div className={sharedStyles.spacer} />
          {itemType === "liquidity" && (
            <>
              <Button
                isRequesting={isLoadingExpected}
                onClick={onCalculateExpectedTokens}
                disabled={
                  !watch(INPUT_NAME) || parseFloat(watch(INPUT_NAME)) === 0
                }
              >
                Calculate expected tokens
              </Button>
              {expectedBlxm && expectedExternal && !isLoadingExpected && (
                <>
                  <div className={sharedStyles.spacer} />
                  <Text tag="p" type="normal">
                    BLXM: {expectedBlxm}
                  </Text>
                  <Text tag="p" type="normal">
                    USDC: {expectedExternal}
                  </Text>
                </>
              )}
              <div className={sharedStyles.spacer} />
            </>
          )}
          <Button
            type="submit"
            onClick={onWithdraw}
            disabled={!watch(INPUT_NAME) || parseFloat(watch(INPUT_NAME)) === 0}
          >
            Withdraw
          </Button>
        </form>
      </Modal>

      <Modal isOpen={isReStakeModalOpen} onClose={handleReStakeCloseModal}>
        <Text tag="p" type="medium">
          You&apos;re going to re-stake this package + reward. Please choose a
          locking period
        </Text>
        <div className={sharedStyles.spacer} />

        <Text tag="p" type="medium">
          APR {APRs?.[chosenPeriodIndex]}%
        </Text>

        <div className={sharedStyles.spacer} />

        <div className={sharedStyles.column}>
          {LOCK_OPTIONS.map((item, index) => (
            <div className={sharedStyles.column} key={item.value}>
              <Button
                onClick={() => onLockOptionClick(item.value, index)}
                className={[
                  styles.lockingButton,
                  watch("lockDaysNumber") === item.value
                    ? styles.lockingActiveButton
                    : styles.lockingInactiveButton,
                ].join(" ")}
              >
                {item.value} days
              </Button>
            </div>
          ))}
        </div>
        <div className={sharedStyles.spacer} />
        <Button type="submit" onClick={onReStake}>
          Re-stake
        </Button>
      </Modal>

      <div className={sharedStyles.column}>
        <Text tag="h1" type="big">
          Available Operations
        </Text>

        <div className={sharedStyles.spacer} />

        <Button
          onClick={() => navigate(ROUTES.LIQUIDITY)}
          isRequesting={isLiquidityLoading}
          disabled={isLiquidityLoading}
        >
          Liquidity Module
        </Button>

        <div className={[styles.row, styles.rowSpacing].join(" ")}>
          <div className={sharedStyles.column}>
            {liquidities?.map((item) => {
              const {
                liquidity,
                isLocked,
                lockingDaysLeft,
                isDays,
                amount,
                position,
              } = item;
              return (
                ( truncateDecimals(Number(formatUnits(String(liquidity), 18)), 3) === "0.000" )  ? "" : 
                <div
                  key={position}
                  className={[styles.row, styles.rowSpacing].join(" ")}
                >
                  <Text type="normal" className={styles.data}>
                    {isLocked ? "Locked liquidity" : "Not locked liquidity"}
                    <Text tag="p" type="normal" className={styles.data}>
                    {
                      Number
                      (
                          ( 
                            ( Number(liquidity) * Number(blxmDecimalsBN) )
                              / 
                              Math.sqrt( Number(blxmDecimalsBN) * Number(tokenDecimalsBN) ) 
                          )
                          / 
                          (10 ** 18)
                      ).toFixed(3)
                    }
                    </Text>
                  </Text>
                  <Text type="normal" className={styles.data}>
                    Your current reward:
                    <Text tag="p" type="normal" className={styles.data}>
                      {Number(formatUnits(String(amount), 18)).toFixed(3)}
                    </Text>
                  </Text>
                  <div className={sharedStyles.spacer} />
                  <Button
                    onClick={() => handleWithdrawClick(item)}
                    className={styles.button}
                    data-testid="retrieve-btn"
                  >
                    {isLocked
                      ? `Remain Locking: ${Number(lockingDaysLeft)} ${
                          isDays ? "d" : "h"
                        }`
                      : "Retrieve"}
                  </Button>
                  <div className={sharedStyles.spacer} />
                </div>
              );
            })}
          </div>
        </div>

        <div className={sharedStyles.spacer} />

        <Button
          onClick={() => navigate(ROUTES.STAKING)}
          isRequesting={isStakingLoading}
          disabled={isStakingLoading}
        >
          Staking module
        </Button>

        <div className={[styles.row, styles.rowSpacing].join(" ")}>
          <div className={sharedStyles.column}>
            {staking?.map((item) => {
              const {
                amount,
                isLocked,
                lockingDaysLeft,
                isDays,
                rewardAmount,
                position,
              } = item;

              return (
                // ( amount == 0 ) ? "" :
                ( truncateDecimals(Number(formatUnits(String(amount), 18)), 3) === "0.000" )  ? "" : 
                <div
                  key={position}
                  className={[styles.row, styles.rowSpacing].join(" ")}
                >
                  <Text type="normal" className={styles.data}>
                    {isLocked ? "Locked staking" : "Not locked staking"}
                    <Text tag="p" type="normal" className={styles.data}>
                      {
                        Number(
                          String( Number(amount) / _ether )
                        ).toFixed(3)
                      }
                    </Text>
                  </Text>
                  <Text type="normal" className={styles.data}>
                    Your current reward:
                    <Text tag="p" type="normal" className={styles.data}>
                      {Number(
                        formatUnits(rewardAmount, 18)
                      ).toFixed(3)}
                    </Text>
                  </Text>
                  <div className={sharedStyles.spacer} />
                  <div className={styles.data}>
                    {!isLocked && (
                      <Button
                        onClick={() => handleReStakeClick(item)}
                        className={styles.button}
                        data-testid="retrieve-btn"
                        disabled={isStakingLoading}
                      >
                        Re-stake
                      </Button>
                    )}
                    {isLocked ? (
                      <Button
                        onClick={() => handleWithdrawClick(item)}
                        className={styles.button}
                        data-testid="retrieve-btn"
                        disabled={isStakingLoading}
                      >
                        Remain Locking: {lockingDaysLeft} {isDays ? "d" : "h"}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleWithdrawClick(item)}
                        type="link"
                        data-testid="retrieve-btn"
                        disabled={isStakingLoading}
                      >
                        Retrieve
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </InvestorsAreaLayout>
  );
};

InvestorsArea.propTypes = {
  liquidities: PropTypes.array,
  getLiquidities: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  retrieveLiquidity: PropTypes.func.isRequired,
  blxmDecimals: PropTypes.string.isRequired,
  tokenDecimals: PropTypes.string.isRequired,
  staking: PropTypes.array,
  getStaking: PropTypes.func.isRequired,
  retrieveStaking: PropTypes.func.isRequired,
  reStake: PropTypes.func.isRequired,
  activeWallet: PropTypes.object.isRequired,
  isLiquidityLoading: PropTypes.bool.isRequired,
  isStakingLoading: PropTypes.bool.isRequired,
  getCalculationsData: PropTypes.func.isRequired,
  getCalculationsDataForStaking: PropTypes.func.isRequired,
  signer: PropTypes.object,
  provider: PropTypes.object,
  APRs: PropTypes.array,
};

InvestorsArea.defaultProps = {
  liquidities: null,
  staking: null,
  signer: null,
  provider: {},
  APRs: ["...", "...", "...", "..."],
};
