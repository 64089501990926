// Here you can change the package indexes which
// are used to calculate expected APR
// insert the index of the package in the corresponding slot

export const STAKING_REF_INDEXES = {
  // Ending 22 April 2024
  THIRTY_DAYS_INDEX: 19, // 30 days locking period
  // Ending 21 June 2024
  NINETY_DAYS_INDEX: 20, // 90 days locking period
  // Ending 19 September 2024
  HALF_A_YEAR_INDEX: 21, // 180 days locking period
  // Ending 17 March 2025
  YEAR_INDEX: 22, // 360 days locking period
};
