import React, { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import logo from "assets/logos/token.svg";
import { Button, sharedStyles } from "components/shared";
import Text from "components/shared/text";
import { MobileContext } from "context";
import { ROUTES } from "enums";
import walletActions from "store/wallet/actions";
import styles from "./style.module.css";

const ClaimingLayout = ({
  children,
  address,
  activeWallet,
  userWallets,
  disconnectWallet,
}) => {
  const isMobile = useContext(MobileContext);
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const navigateToMainPage = () => navigate(ROUTES.INITIAL);

  return (
    <div className={[sharedStyles.column, styles.container].join(" ")}>
      <div className={styles.wrapper}>
        {isMobile && (
          <div className={[sharedStyles.row, styles.mobileHeader].join(" ")}>
            <div onClick={navigateToMainPage}>
              <img
                src={logo}
                className={[styles.logo, styles.header].join(" ")}
                alt="main-logo"
                data-testid="main-logo"
              />
            </div>
            <Text tag="h1" type="big" className={styles.mobileHeaderText}>
              BLXM Investor Portal
            </Text>
          </div>
        )}
        <div className={isMobile ? sharedStyles.column : sharedStyles.row}>
          {activeWallet && (
            <>
              <div style={{ flex: 3 }}>
                {!!address && (
                  <div
                    className={
                      isMobile ? sharedStyles.column : sharedStyles.row
                    }
                  >
                    <Text
                      tag="p"
                      type="small"
                      style={
                        isMobile
                          ? { paddingTop: 16, paddingBottom: 8 }
                          : { paddingRight: 6 }
                      }
                    >
                      WALLET:
                    </Text>
                    <Text tag="p" type="small" className={styles.addressText}>
                      {address}
                    </Text>
                  </div>
                )}
                <div
                  style={{ justifyContent: "space-evenly" }}
                  className={sharedStyles.row}
                >
                  {userWallets?.length > 1 && (
                    <Button
                      onClick={() => {
                        navigate(ROUTES.CLAIMING_WALLETS);
                      }}
                      type="link"
                    >
                      CHANGE CONTRACT
                    </Button>
                  )}
                  {activeWallet && (
                    <Button onClick={disconnectWallet} type="link">
                      DISCONNECT
                    </Button>
                  )}
                </div>
              </div>
              <div className={sharedStyles.spacer} />
            </>
          )}
          {pathname !== "/" && (
            <Button
              className={styles.manageButton}
              onClick={navigateToMainPage}
            >
              Back to main page
            </Button>
          )}
        </div>
        {!isMobile && (
          <div className={sharedStyles.column}>
            <div onClick={navigateToMainPage}>
              <img
                src={logo}
                className={[styles.logo, styles.header].join(" ")}
                alt="main-logo"
              />
            </div>
            <Text tag="h1" type="big">
              BLXM Investor Portal
            </Text>
          </div>
        )}
      </div>

      <div className={styles.childWrapper}>{children}</div>
    </div>
  );
};

ClaimingLayout.propTypes = {
  children: PropTypes.element.isRequired,
  address: PropTypes.string.isRequired,
  userWallets: PropTypes.array.isRequired,
  activeWallet: PropTypes.object,
  disconnectWallet: PropTypes.func.isRequired,
};

ClaimingLayout.defaultProps = {
  activeWallet: null,
};

const mapStateToProps = (state) => ({
  userWallets: state.wallet.userWallets,
  activeWallet: state.wallet.activeWallet,
  address: state.wallet.address,
});

const mapDispatchToProps = {
  disconnectWallet: walletActions.Creators.disconnectWalletRequest,
};

export { ClaimingLayout as ClaimingLayoutComponent };
export default connect(mapStateToProps, mapDispatchToProps)(ClaimingLayout);
