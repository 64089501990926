import { connect } from "react-redux";
import { Staking } from "components/Staking";
import walletActions from "store/wallet/actions";
import stakingActions from "store/staking/actions";
import messageActions from "store/message/actions";

const mapStateToProps = (state) => ({
  error: state.wallet.error,
  activeWallet: state.wallet.activeWallet,
  totalStaking: state.staking.totalStaking,
  rewardsPerHour: state.staking.rewardsPerHour,
  balance: state.wallet.balance,
  provider: state.wallet.provider,
  isUpdating: state.wallet.isLoading,
  signer: state.wallet.signer,
  APRs: state.staking.APRs,
});

const mapDispatchToProps = {
  connectWallet: walletActions.Creators.connectWalletRequest,
  getCalculationsDataForStaking:
    stakingActions.Creators.getCalculationsDataForStakingRequest,
  postStaking: stakingActions.Creators.postStakingRequest,
  enqueueSnackbar: messageActions.Creators.enqueueSnackbar,
  refreshBalance: walletActions.Creators.refreshBalanceRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Staking);
