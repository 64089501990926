import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  connectWalletRequest: ["routingOptions"], // CONNECT_WALLET_REQUEST
  // CONNECT_WALLET_REQUEST
  connectWalletSuccess: [
    "address",
    "signer",
    "provider",
    "validationSkip",
    "balance",
  ],
  connectWalletFailure: ["error"], // CONNECT_WALLET_FAILURE
  disconnectWalletRequest: [], // DISCONNECT_WALLET_REQUEST
  disconnectWalletSuccess: [], // DISCONNECT_WALLET_SUCCESS
  disconnectWalletFailure: ["error"], // DISCONNECT_WALLET_FAILURE
  // SET_ACTIVE_WALLET_REQUEST
  setActiveWalletRequest: [
    "activeWallet",
    "redirectSuccessUrl",
    "redirectErrorUrl",
  ],
  setActiveWalletSuccess: ["activeWallet"], // SET_ACTIVE_WALLET_SUCCESS
  setActiveWalletFailure: ["error"], // SET_ACTIVE_WALLET_FAILURE
  // SET_USER_WALLETS_REQUEST
  setUserWalletsRequest: [
    "userWallets",
    "redirectSuccessUrl",
    "redirectErrorUrl",
  ],
  setUserWalletsSuccess: ["userWallets"], // SET_USER_WALLETS_SUCCESS
  setUserWalletsFailure: ["error"], // SET_USER_WALLETS_FAILURE

  refreshBalanceRequest: [], // REFRESH_BALANCE_REQUEST
  refreshBalanceSuccess: ["balance"], // REFRESH_BALANCE_SUCCESS
  refreshBalanceFailure: ["error"], // REFRESH_BALANCE_FAILURE

  setWallet: ["address"], // SET_WALLET
  setNetwork: ["hexChainId"], // SET_NETWORK
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
