import React, { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, sharedStyles } from "components/shared";
import Text from "components/shared/text";
import { MobileContext } from "context";
import { COLORS, ROUTES } from "enums";
import walletActions from "store/wallet/actions";
import logo from "assets/logos/token.svg";
import styles from "./style.module.css";

const InvestorsAreaLayout = ({
  children,
  address,
  activeWallet,
  userWallets,
  disconnectWallet,
}) => {
  const isMobile = useContext(MobileContext);

  const navigate = useNavigate();

  const navigateToMainPage = () => navigate(ROUTES.INITIAL);

  return (
    <div className={[sharedStyles.column, styles.container].join(" ")}>
      <div className={styles.wrapper}>
        {isMobile && (
          <div className={[sharedStyles.row, styles.mobileHeader].join(" ")}>
            <div onClick={navigateToMainPage}>
              <img
                src={logo}
                className={[styles.logo, styles.header].join(" ")}
                alt="main-logo"
              />
            </div>
            <Text tag="h1" type="big" className={styles.mobileHeaderText}>
              BLXM Manage my tokens
            </Text>
          </div>
        )}
        <div className={isMobile ? sharedStyles.column : sharedStyles.row}>
          <div style={{ flex: 3 }}>
            {activeWallet && (
              <>
                {!!address && (
                  <div
                    className={
                      isMobile ? sharedStyles.column : sharedStyles.row
                    }
                  >
                    <Text
                      tag="p"
                      type="small"
                      style={
                        isMobile
                          ? { paddingTop: 16, paddingBottom: 8 }
                          : { paddingRight: 6, backgroundColor: COLORS.WHITE }
                      }
                    >
                      WALLET:
                    </Text>
                    <Text tag="p" type="small" className={styles.addressText}>
                      {address}
                    </Text>
                  </div>
                )}
                <div
                  style={{ justifyContent: "space-evenly" }}
                  className={isMobile ? sharedStyles.column : sharedStyles.row}
                >
                  {userWallets?.length > 1 && (
                    <Button
                      onClick={() => {
                        navigate(ROUTES.CLAIMING_WALLETS, {
                          redirectUrl: ROUTES.LIQUIDITY,
                        });
                      }}
                      type="link"
                    >
                      CHANGE CONTRACT
                    </Button>
                  )}
                  <Button onClick={disconnectWallet} type="link">
                    DISCONNECT
                  </Button>
                </div>
              </>
            )}
          </div>
          {activeWallet && <div className={sharedStyles.spacer} />}
          <Button className={styles.manageButton} onClick={navigateToMainPage}>
            Back to main page
          </Button>
        </div>
        {!isMobile && (
          <div className={sharedStyles.column}>
            <div onClick={navigateToMainPage}>
              <img
                src={logo}
                className={[styles.logo, styles.header].join(" ")}
                alt="main-logo"
              />
            </div>
            <Text tag="h1" type="big">
              BLXM: Manage my tokens
            </Text>
          </div>
        )}
      </div>

      <div className={styles.childWrapper}>{children}</div>
    </div>
  );
};

InvestorsAreaLayout.propTypes = {
  children: PropTypes.element.isRequired,
  address: PropTypes.string.isRequired,
  userWallets: PropTypes.array.isRequired,
  activeWallet: PropTypes.object,
  disconnectWallet: PropTypes.func.isRequired,
};

InvestorsAreaLayout.defaultProps = {
  activeWallet: null,
};

const mapStateToProps = (state) => ({
  userWallets: state.wallet.userWallets,
  activeWallet: state.wallet.activeWallet,
  address: state.wallet.address,
});

const mapDispatchToProps = {
  disconnectWallet: walletActions.Creators.disconnectWalletRequest,
  connectWallet: walletActions.Creators.connectWalletRequest,
};

export { InvestorsAreaLayout as InvestorsAreaLayoutComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestorsAreaLayout);
