import React from "react";
import { useParams } from "react-router-dom";
import ClaimingLayout from "layouts/ClaimingLayout";
import { Text } from "components/shared";

export const NFTicket = () => {
  const { id } = useParams();

  return (
    <ClaimingLayout>
      <Text>current page id: {id}</Text>
    </ClaimingLayout>
  );
};
