// @ts-nocheck
import { ethers } from "ethers";
import { all, call, put, takeLeading } from "redux-saga/effects";
import { ContractAbi } from "services/abi";
import messageActions from "store/message/actions";
import Actions from "./actions";

const {
  Creators: { enqueueSnackbar },
} = messageActions;

const {
  Types: { READ_CONTRACT_REQUEST },
  Creators: { readContractSuccess, readContractFailure },
} = Actions;

const getDiff = (date) => {
  const currentDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  return (+date - +currentDate) / oneDay;
};

const getVestingRemainingDays = ({ activeWallet, walletTokens }) => {
  const contractStartDt = new Date(walletTokens.contractStartTime);
  const contractCliffDt = new Date(walletTokens.contractStartTime);

  let walletCliff = 0;
  if (activeWallet?.cliff) {
    walletCliff = parseInt(activeWallet.cliff, 10);
    walletCliff = walletCliff > 0 ? walletCliff : 0;
  }

  let walletVesting = 0;
  if (activeWallet?.vesting) {
    walletVesting = parseInt(activeWallet.vesting, 10);
  }

  contractStartDt.setDate(
    contractStartDt.getDate() + walletCliff + walletVesting
  );

  contractCliffDt.setDate(contractCliffDt.getDate() + walletCliff);

  const vestingRemainingDays =
    getDiff(contractStartDt) > 0 ? Math.floor(getDiff(contractStartDt)) : 0;

  const cliffRemainingDays =
    getDiff(contractCliffDt) > 0 ? Math.floor(getDiff(contractCliffDt)) : 0;

  return { cliffRemainingDays, vestingRemainingDays };
};

export function* readContract({ activeWallet, signer }) {
  const contract = new ethers.Contract(
    activeWallet.contract,
    JSON.parse(ContractAbi),
    signer
  );

  const walletTokens = {};

  try {
    const [amount, remaining, startTime] = yield all([
      call(contract.getCurrentAmountToClaim, activeWallet.address),
      call(contract.getRemainingGrant, activeWallet.address),
      call(contract.startTime),
    ]);

    walletTokens.availableTokens = amount || BigNumber.from(0);
    walletTokens.remainingTokens = remaining || BigNumber.from(0);
    walletTokens.futureTokens = remaining.sub(amount);
    walletTokens.contractStartTime = startTime.toNumber() * 1000;

    const { cliffRemainingDays, vestingRemainingDays } =
      getVestingRemainingDays({ activeWallet, walletTokens });

    yield put(
      readContractSuccess(
        walletTokens,
        cliffRemainingDays,
        vestingRemainingDays,
        contract
      )
    );
  } catch (error) {
    yield put(readContractFailure(error));
    yield put(
      enqueueSnackbar({
        message: "Contract reading failure",
        options: { variant: "error" },
      })
    );
  }
}

export function* contractSaga() {
  yield takeLeading(READ_CONTRACT_REQUEST, readContract);
}
