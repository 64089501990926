import { ethers } from "ethers";
// import { AlchemyProvider } from '@ethersproject/providers';
import stakerContractJson from "services/BLXMStaker.json";
import liquidityRouterAbiJson from "services/BLXMRouter.json";
import tokenAbiJson from "services/MockedTokens.json";
import treasuryAbi from "services/Treasury.json";

import { EventClassFactory } from "./eventSubscription";
import * as Events from "./events.types";

import { CHAIN_TYPES } from "enums/CHAIN_TYPES";


export class ChainCode {
    static _lock: boolean = false;

    public static web3provider: ethers.BrowserProvider;
    public static chainID: any;
    public static networkName: any;
    public static accounts: any[];
    public static signer : any;

    public static stakerAddress: any;
    public static routerAddress: any;
    public static blxmAddress: any;
    public static tokenAddress: any;

    public static liquidityRouterContract: any;
    public static stakerContract: any;
    public static blxmContract: any;   
    public static pairTokenContract: any;  
    public static treasuryContract: any;
    // public static nfticketContract: any;
    

    static getChain( _chainType: any  ) : any {
      // console.log("id = %d name = %s", _chainType.id, _chainType.name);
      return _chainType;
    }
    static async initWallet() : Promise<string[]> {
      var accounts: any[];

      accounts = await window.ethereum.request({
        "method": "eth_accounts",
        "params": []
      });
      if ( accounts.length > 0 ) {
        try {
          console.log ("already connected to accounts[0]", accounts[0]);
          ChainCode.web3provider = new ethers.BrowserProvider(window.ethereum);
          ChainCode.accounts = accounts;
          ChainCode.signer = await ChainCode.web3provider.getSigner(accounts[0]);
          ChainCode.chainID = (await ChainCode.web3provider.getNetwork()).chainId;
          const chainType = CHAIN_TYPES.find((item) =>  Number((ChainCode.getChain(item)).id) == Number(ChainCode.chainID)  );
          if (!chainType) {
            throw new Error("accounts already known: locally gettingLost"); // TODO UI needs to catch this --> sagas
          }
          const { name, url } = chainType;
          console.log("locally found chain name %s and URL is %s", name, url);
          ChainCode.networkName = name;
        }
        catch(error) {
          console.log("CC: wrong chain");
          return(Promise.resolve([]));
        }

      }
      else {
        if ( ! ChainCode._lock ) {
          ChainCode._lock = true;
          console.log ("not yet connected to accounts");
          ChainCode.web3provider = new ethers.BrowserProvider(window.ethereum);
          try {
            accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
            console.log("now connected to accounts of length %d with entry %s", accounts.length, accounts[0]);
            /*
            accounts = await window.ethereum.request({
              method: "eth_accounts",
            });
            */
            ChainCode.accounts = accounts;
            ChainCode.signer = await ChainCode.web3provider.getSigner(accounts[0]);
            ChainCode.chainID = (await ChainCode.web3provider.getNetwork()).chainId;

            const userWallets = accounts.map((account: any) => {
              console.log("verifying wallet %s", account);
              const chainType = CHAIN_TYPES.find((item) =>  Number((ChainCode.getChain(item)).id) == Number(ChainCode.chainID)  );
              if (!chainType) {
                throw new Error("locally gettingLost"); // TODO UI needs to catch this --> sagas
              }
              const { name, url } = chainType;
              console.log("found chain name %s and URL is %s", name, url);
              ChainCode.networkName = name;
            });
          }
          catch(error) {
            console.log("CC: user rejected to connect");
            return(Promise.resolve([]));
          }
        }
      }
      await ChainCode.initContracts(ChainCode.signer, ChainCode.chainID);
      ChainCode._lock = false;
      return Promise.resolve(accounts);
    }

    /***
     * 
     * @notice here we initialize the smart contracts for the frontend
     *     TODO: this should be a schema or at least JSON with a set of relevant contracts
     * @param signer ethers web3 Signer
     * @return stakerContract
     *     
     ***/
    static async initContracts(signer : any, chainID: any) : Promise<boolean> {

      let ret = false;
      if ( chainID == 56 )
      {
        const liquidityRouterContractAddress = process.env.REACT_APP_BSC_LSC_ADDRESS!;
        ChainCode.routerAddress = liquidityRouterContractAddress;
        const stakerContractAddress = process.env.REACT_APP_BSC_SSC_ADDRESS!;
        ChainCode.stakerAddress = stakerContractAddress;
        const blxmContractAddress = process.env.REACT_APP_BSC_BLXM_ADDRESS!;
        ChainCode.blxmAddress = blxmContractAddress;
        const pairTokenContractAddress = process.env.REACT_APP_BSC_PAIR_TOKEN_ADDRESS!;
        ChainCode.tokenAddress = pairTokenContractAddress;
        const treasuryContractAddress = process.env.REACT_APP_BSC_TSC_ADDRESS!;
    
        ChainCode.stakerContract = new ethers.Contract(
          stakerContractAddress,
          stakerContractJson.abi,
          signer
        );
        ChainCode.blxmContract = new ethers.Contract(
          blxmContractAddress,
          tokenAbiJson.abi,
          signer
        );
        console.log("blxm address is %s", await ChainCode.blxmContract.getAddress());
        ChainCode.pairTokenContract = new ethers.Contract(
          pairTokenContractAddress,
          tokenAbiJson.abi,
          signer
        );
        ChainCode.liquidityRouterContract = new ethers.Contract(
          liquidityRouterContractAddress,
          liquidityRouterAbiJson.abi,
          signer
        );
        ChainCode.treasuryContract = new ethers.Contract(
          treasuryContractAddress,
          treasuryAbi,
          signer
        );
        console.log("routerContract is %s stakerContractAddress is %s", 
          await ChainCode.liquidityRouterContract.getAddress(), 
          stakerContractAddress
        );
        
        ret = true;
      }

      return Promise.resolve(ret);
    };

    static getContracts(chainId: any) : 
      {
        liquidityRouterContract: any,
        routerAddress: any,
        stakerContract: any,
        stakerAddress: any,
        blxmContract: any,
        blxmAddress: any,
        pairTokenContract: any,
        tokenAddress: any,
        treasuryContract: any
      } {
      if( chainId == 56 ) {
        console.log("chainID 56");
        console.log("liquidity routerAddress %s", ChainCode.routerAddress);
        
        return {
          liquidityRouterContract: ChainCode.liquidityRouterContract,
          routerAddress: ChainCode.routerAddress,
          stakerContract: ChainCode.stakerContract,
          stakerAddress: ChainCode.stakerAddress,
          blxmContract: ChainCode.blxmContract,
          blxmAddress: ChainCode.blxmAddress,
          pairTokenContract: ChainCode.pairTokenContract,
          tokenAddress: ChainCode.tokenAddress,
          treasuryContract: ChainCode.treasuryContract,
        };
    } else {
      console.log("chainID unknown");
      return {};
    }
  }

  } 
