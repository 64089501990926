import React from "react";
import PropTypes from "prop-types";
import Text from "../text";
import styles from "./style.module.css";

const Label = ({ children, ...props }) => (
  <div className={styles.container} {...props}>
    <Text className={styles.text} tag="p" type="normal">
      {children}
    </Text>
  </div>
);

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Label.defaultProps = {
  children: "",
};

export default Label;
