import { createReducer } from "reduxsauce";
import { CHAIN_TYPES } from "enums/CHAIN_TYPES";
import actions from "./actions";

const { Types } = actions;
const INITIAL_STATE = {
  address: "",
  activeWallet: null,
  balance: {},
  validationSkip: false,
  userWallets: [],
  provider: null,
  signer: null,
  isLoading: false,
  error: null,
};

const request = (state) => ({
  ...state,
  isLoading: true,
  error: null,
});

const failure = (state, { error }) => ({
  ...state,
  isLoading: false,
  error,
});

const connectWalletSuccess = (
  state,
  { address, signer, provider, validationSkip, balance }
) => ({
  ...state,
  address,
  signer,
  validationSkip,
  balance,
  provider,
});

const connectWalletFailure = (state, { error }) => ({
  ...state,
  address: "",
  signer: null,
  error,
  isLoading: false,
});

const disconnectWalletSuccess = (state) => ({
  ...state,
  provider: null,
  signer: null,
  address: "",
  userWallets: [],
  balance: {},
  activeWallet: null,
  isLoading: false,
});

const setUserWalletsSuccess = (state, { userWallets }) => ({
  ...state,
  userWallets,
  isLoading: false,
});

const setActiveWalletSuccess = (state, { activeWallet }) => ({
  ...state,
  activeWallet,
  isLoading: false,
});

const refreshBalanceSuccess = (state, { balance }) => ({
  ...state,
  balance,
  isLoading: false,
});

const setWallet = (state, { address }) => ({
  ...state,
  address,
  activeWallet: {
    ...state.activeWallet,
    address,
  },
  userWallets: state.userWallets.map((item, index) =>
    index === 0 ? { ...item, address } : item
  ),
});

const setNetwork = (state, { hexChainId }) => {
  const { name, url } = CHAIN_TYPES.find(
    (item) => item.id === parseInt(hexChainId, 16)
  );

  return {
    ...state,
    activeWallet: {
      ...state.activeWallet,
      chain_type: name,
      networkUrl: url,
    },
    userWallets: state.userWallets.map((item) => ({
      ...item,
      chain_type: name,
      networkUrl: url,
    })),
  };
};

export const HANDLERS = {
  [Types.CONNECT_WALLET_REQUEST]: request,
  [Types.CONNECT_WALLET_SUCCESS]: connectWalletSuccess,
  [Types.CONNECT_WALLET_FAILURE]: connectWalletFailure,
  [Types.DISCONNECT_WALLET_REQUEST]: request,
  [Types.DISCONNECT_WALLET_SUCCESS]: disconnectWalletSuccess,
  [Types.DISCONNECT_WALLET_FAILURE]: failure,
  [Types.SET_USER_WALLETS_REQUEST]: request,
  [Types.SET_USER_WALLETS_SUCCESS]: setUserWalletsSuccess,
  [Types.SET_USER_WALLETS_FAILURE]: failure,
  [Types.SET_ACTIVE_WALLET_REQUEST]: request,
  [Types.SET_ACTIVE_WALLET_SUCCESS]: setActiveWalletSuccess,
  [Types.SET_ACTIVE_WALLET_FAILURE]: failure,
  [Types.REFRESH_BALANCE_REQUEST]: request,
  [Types.REFRESH_BALANCE_SUCCESS]: refreshBalanceSuccess,
  [Types.REFRESH_BALANCE_FAILURE]: failure,
  [Types.SET_WALLET]: setWallet,
  [Types.SET_NETWORK]: setNetwork,
};

export default createReducer(INITIAL_STATE, HANDLERS);
