import { connect } from "react-redux";
import { MainPage } from "components/MainPage";

const mapStateToProps = (state) => ({
  error: state.wallet.error,
  activeWallet: state.wallet.activeWallet,
  validationSkip: state.wallet.validationSkip,
});

export default connect(mapStateToProps, null)(MainPage);
