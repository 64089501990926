import React, { useRef } from "react";
import PropTypes from "prop-types";
import useOutsideClick from "hooks/useOutsideClick";
import Portal from "../portal";
import styles from "./style.module.css";

const Modal = ({ children, isOpen, onClose, ...props }) => {
  const ref = useRef();

  const handleClose = () => {
    if (isOpen) {
      onClose();
    }
  };

  useOutsideClick(ref, handleClose);

  return (
    <Portal>
      {isOpen && (
        <div className={styles.backdrop}>
          <div ref={ref} className={styles.content} {...props}>
            {children}
          </div>
        </div>
      )}
    </Portal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  children: null,
};

export default Modal;
