import { Navigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const ActiveWalletRoute = ({ children, redirectUrl }) => {
  const activeWallet = useSelector((state) => state?.wallet?.activeWallet);

  return activeWallet ? children : <Navigate to={redirectUrl} replace />;
};

ActiveWalletRoute.propTypes = {
  children: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};
