import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.css";

const Text = ({ children, className, type, tag, ...props }) => {
  const classNames = `${styles.default} ${type && styles[type]} ${className}`;

  switch (tag) {
    case "h1":
      return (
        <h1 className={classNames} {...props}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={classNames} {...props}>
          {children}
        </h2>
      );
    case "p":
      return (
        <p className={classNames} {...props}>
          {children}
        </p>
      );
    default:
      return (
        <span className={classNames} {...props}>
          {children}
        </span>
      );
  }
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  tag: PropTypes.string,
};

Text.defaultProps = {
  type: "",
  tag: "",
  className: "",
};

export default Text;
