import { REACT_APP_API_URI } from "config";

const api = {
  url: REACT_APP_API_URI,
  suffix: "",
  timeout: 200000,
  getBaseUrl() {
    return this.url;
  },
};

Object.freeze(api);

export default api;
