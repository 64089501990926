import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import {
  LiquidityHome,
  InvestorsArea,
  MainPage,
  Staking,
  NFTicket,
} from "../containers";
import { MobileContext, SmallMobileContext, TabletContext } from "../context";
import { ROUTES } from "../enums";
import { ActiveWalletRoute } from "../HOC/ActiveWalletRoute";
import useNotifier from "../hooks/useNotifier";
import { REACT_APP_ENV_LABEL } from "../config";
import walletActions from "../store/wallet/actions";
import { Label } from "./shared";
import { ChainCode } from "../web3/chainCode";
import { CHAIN_TYPES } from "../enums/CHAIN_TYPES";


const App = ({
  provider,
  activeWallet,
  disconnectWallet,
  setWallet,
  setNetwork,
  } : {
    provider: any, 
    activeWallet: any, 
    disconnectWallet: any, 
    setWallet: any, 
    setNetwork: any,
  }) => {
  useNotifier();

  const [isSmallMobile, setIsSmallMobile] = useState(
    document.documentElement.clientWidth <= 450
  );
  const [isMobile, setIsMobile] = useState(
    document.documentElement.clientWidth <= 768
  );
  const [isTablet, setIsTablet] = useState(
    document.documentElement.clientWidth <= 992
  );

  const [networkName, setNetworkName] = useState("");

  const [accounts, setAccounts] = useState([""]);
  const [chainId, setChainId] = useState("");

  const handleResize = () => {
    const { clientWidth } = document.documentElement;
    setIsSmallMobile(clientWidth <= 374);
    setIsMobile(clientWidth <= 768);
    setIsTablet(clientWidth <= 991);
  };

  useEffect(() => {
    ( 
      async () => 
      {
        console.log("looking for _accounts");
        const accounts = await ChainCode.initWallet();
        if( accounts.length )
        {
          console.log("_accounts[0] = %s", accounts[0]);
          setAccounts(accounts);
        } else {
          console.log("connecting to wallet rejected");
        }
      } 
    )();

    if (ChainCode.web3provider) {
      setChainId(ChainCode.chainID);
      setNetworkName(ChainCode.networkName);
      console.log("App.tsx - network ID = %d name is %s", ChainCode.chainID, ChainCode.networkName);
        
    }
    // initWallet();
    // let chainId = ChainCode.chainID;
    /*
        let networkName = "UNKNOWN CHAIN";
        let network =  CHAIN_TYPES.forEach((item) => {
        console.log("comparing %s with %s", chainId, item.id);        
        if (String(item.id) === chainId) {
          networkName = item.name;
          }
        });
        setNetworkName(networkName);
    */
  }, [provider, activeWallet]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  // eslint-disable-next-line consistent-return
  // TODO IMPORTANT event management
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: any) => {
        if (accounts) {
          setWallet(accounts[0]);
        }
      };

      const handleChainChanged = (_hexChainId: any) => {
        setNetwork(_hexChainId);
        disconnectWallet();
      };

      const handleDisconnect = () => {
        disconnectWallet();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [disconnectWallet, provider, setNetwork, setWallet]);


  // TODO: use react-helmet-async to change title and description on each page dynamically
  return (
    <TabletContext.Provider value={isTablet}>
      <MobileContext.Provider value={isMobile}>
        <SmallMobileContext.Provider value={isSmallMobile}>
          <React.StrictMode>
            {!!REACT_APP_ENV_LABEL && (
              <Label style={{ top: 60 }}>{REACT_APP_ENV_LABEL}</Label>
            )}
            {!!networkName && <Label>{`${networkName} network`}</Label>}
            <Routes>
              <Route key="main" path={ROUTES.INITIAL} element={<MainPage/>} />

              <Route key="stake" path={ROUTES.STAKING} element={<Staking/>} />

              <Route key="liquid" path={ROUTES.LIQUIDITY} element={<LiquidityHome/>} />
              <Route
                key="investments"
                path={ROUTES.INVESTMENT_MANAGEMENT}
                element={
                  <ActiveWalletRoute redirectUrl={ROUTES.INITIAL}>
                    <InvestorsArea />
                  </ActiveWalletRoute>
                }
              />

              <Route path={ROUTES.NFTICKET} element={<NFTicket />} />

              <Route path="*" element={<MainPage />} />
            </Routes>
          </React.StrictMode>
        </SmallMobileContext.Provider>
      </MobileContext.Provider>
    </TabletContext.Provider>
  );
};

App.propTypes = {
  provider: PropTypes.object,
  activeWallet: PropTypes.object,
  disconnectWallet: PropTypes.func.isRequired,
  setWallet: PropTypes.func.isRequired,
  setNetwork: PropTypes.func.isRequired,
};

App.defaultProps = {
  activeWallet: {},
  provider: {},
};

const mapStateToProps = (state: any) => ({
  provider: state.wallet.provider,
  activeWallet: state.wallet.activeWallet,
});

const mapDispatchToProps = {
  disconnectWallet: walletActions.Creators.disconnectWalletRequest,
  setWallet: walletActions.Creators.setWallet,
  setNetwork: walletActions.Creators.setNetwork,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
