// @ts-nocheck
import { all, call, put, takeLeading } from "redux-saga/effects";
import { bigNumberToNormal } from "services/tokenFormatter";
import messageActions from "store/message/actions";
import {
  REACT_APP_MIN_COEFFICIENT,
} from "config";
import api from "services/api";
import calculateLockingDays from "services/calculateLockingDays";
import getTransactionUrl from "services/getTransactionUrl";
import { MESSAGES } from "enums";
import Actions from "./actions";
import { ChainCode } from "../../web3/chainCode";
import { walletlink } from "web3modal/dist/providers/connectors";
import { AbiCoder, ethers } from "ethers";
var sqrt = require('bigint-isqrt');

const ether : number = 10 ** 18;
const gwei: number = 10 ** 9;


const {
  Creators: { enqueueSnackbar },
} = messageActions;

const {
  Types: {
    GET_LIQUIDITIES_REQUEST,
    POST_LIQUIDITY_REQUEST,
    RETRIEVE_LIQUIDITY_REQUEST,
    GET_CALCULATIONS_DATA_REQUEST,
  },
  Creators: {
    getLiquiditiesSuccess,
    getLiquiditiesFailure,
    postLiquiditySuccess,
    postLiquidityFailure,
    retrieveLiquiditySuccess,
    retrieveLiquidityFailure,
    getCalculationsDataSuccess,
    getCalculationsDataFailure,
  },
} = Actions;

async function _getRatio(
  {
    walletAddress,
    liquidityRouterContract,
    tokenAddress
  }:
    {
      walletAddress: any,
      liquidityRouterContract: any,
      tokenAddress: any
    }
) {
    let ratio = await liquidityRouterContract.getRatio(tokenAddress);
    console.log("_getRATIO: RATIO is %s", ratio);
    return (ratio);
}

async function _getDecimals(
  { router }:
    { router: any }
) {
  return await router.decimals();
}

async function _getPeriods(
  {
    walletAddress,
    liquidityRouterContract,
    tokenAddress,
    session
  }:
    {
      walletAddress: any,
      liquidityRouterContract: any,
      tokenAddress: any,
      session: any
    }
) {
  const tx = await liquidityRouterContract.getPeriods.populateTransaction(tokenAddress, session);
  let runner = liquidityRouterContract.runner;
  let retData = await runner.call(tx);
  let abiCoder = new ethers.AbiCoder();
  let [_amountPerHours, _startHour, _endHour] = abiCoder.decode(["uint256", "uint32", "uint32"], retData)
  return { amountPerHours: _amountPerHours, startHour: _startHour, endHour: _endHour };
}

async function _hoursToSession(
  {
    walletAddress,
    liquidityRouterContract,
    tokenAddress,
    hourUnix,
  }:
    {
      walletAddress: any,
      liquidityRouterContract: any,
      tokenAddress: any,
      hourUnix: any,
    }
) {
  let _ret = await liquidityRouterContract.hoursToSession(tokenAddress, hourUnix);
  return _ret;
}

async function _getAllPositionLength(
  { walletAddress, liquidityRouterContract }:
    { walletAddress: any, liquidityRouterContract: any }) {
  return await liquidityRouterContract.allPositionLength(walletAddress);
}

async function _getLiquidityPackage(
  { walletAddress, index, liquidityRouterContract }:
    { walletAddress: any, index: any, liquidityRouterContract: any }
) {
  return await liquidityRouterContract.allPosition(walletAddress, index);
}

async function _getLiquidityEstimatedRewards(
  { walletAddress, index, liquidityRouterContract }:
    { walletAddress: any, index: any, liquidityRouterContract: any }
) {

  const tx = await liquidityRouterContract.calcRewards.populateTransaction(walletAddress, index);
  let runner = liquidityRouterContract.runner;
  let retData = await runner.call(tx);
  let abiCoder = new ethers.AbiCoder();
  let [_amount, _isLocked] = abiCoder.decode(["uint", "bool"], retData)
  return { amount: _amount, isLocked: _isLocked };
}

export default function* getLiquidities(
  { activeWallet, signer, provider }:
    { activeWallet: any, signer: any, provider: any }
) {
  const { address: walletAddress } = activeWallet;
  const { liquidityRouterContract, routerAddress } = ChainCode.getContracts(ChainCode.chainID);

  try {
    const liquidityLength = yield call(_getAllPositionLength, {
      walletAddress,
      liquidityRouterContract,
    });
    const liquidityArray = Array(parseInt(liquidityLength, 10)).fill({});

    const response = yield all(
      liquidityArray.map((_liquidity, index) =>
        all([
          call(_getLiquidityPackage, { walletAddress, index, liquidityRouterContract }),
          call(_getLiquidityEstimatedRewards, {
            walletAddress,
            index,
            liquidityRouterContract: liquidityRouterContract,
          }),
        ])
      )
    );

    const flatResponse = response.map((item, index) => {
      const [lockingDaysLeft, isLocked, isDays] = calculateLockingDays(
        item[0].startHour,
        item[0].endLocking
      );


      return {
        liquidity: item[0].liquidity,
        amount: item[1].amount,
        position: index,
        lockingDaysLeft,
        isLocked,
        isDays,
      }
    });

    const liquidities = flatResponse.filter((item) => item.liquidity !== "0");

    yield put(getLiquiditiesSuccess(liquidities));
  } catch (error) {
    yield put(getLiquiditiesFailure(error));
    yield put(
      enqueueSnackbar({
        message: MESSAGES.contractConnectError3,
        options: { variant: "error" },
      })
    );
  }
}

async function _getAllowance({ walletAddress, contractAddress, contract }) {
  return await contract.allowance(walletAddress, contractAddress);
}

async function _approve({ provider, address, tokensValue, walletAddress }) {
  let tx = await provider.approve(address, tokensValue);
  let receipt = await tx.wait();
}

async function _addLiquidity(
  {
    blxmTokenValue,
    externalTokenValue,
    blxmTokensValueMin,
    externalTokensValueMin,
    walletAddress,
    deadline,
    lockDaysNumber,
    liquidityRouterContract,
    tokenAddress,
  }:
    {
      blxmTokenValue: any,
      externalTokenValue: any,
      blxmTokensValueMin: any,
      externalTokensValueMin: any,
      walletAddress: any,
      deadline: any,
      lockDaysNumber: any,
      liquidityRouterContract: any,
      tokenAddress: any,
    }
) {
  console.log(
    "_ADD LIQUIDITY %s %s %s",
    Number(blxmTokenValue).toLocaleString('fullwide', {useGrouping:false}), 
    Number(externalTokenValue).toLocaleString('fullwide', {useGrouping:false}), 
    Number(blxmTokensValueMin).toLocaleString('fullwide', {useGrouping:false}) 
  );
  let receipt = await liquidityRouterContract.addLiquidity(
    tokenAddress,
    Number(blxmTokenValue).toLocaleString('fullwide', {useGrouping:false}),
    Number(externalTokenValue).toLocaleString('fullwide', {useGrouping:false}),
    Number(blxmTokensValueMin).toLocaleString('fullwide', {useGrouping:false}),
    externalTokensValueMin,
    walletAddress,
    deadline,
    lockDaysNumber
  );
  await receipt.wait();
}

function* _approveLiquidity(
  {
    blxmTokenValue,
    externalTokenValue,
    walletAddress,
    liquidityRouterContract,
    blxmContract,
    pairTokenContract,
  }:
    {
      blxmTokenValue: any,
      externalTokenValue: any,
      walletAddress: any,
      liquidityRouterContract: any,
      blxmContract: any,
      pairTokenContract: any,
    }
) {
  try {
    yield call(_approve, {
      provider: blxmContract,
      address: liquidityRouterContract.target,
      tokensValue: blxmTokenValue,
      walletAddress,
    });
    yield call(_approve, {
      provider: pairTokenContract,
      address: liquidityRouterContract.target,
      tokensValue: externalTokenValue,
      walletAddress,
    });
  } catch (error) {
    yield put(postLiquidityFailure(error));
    yield put(
      enqueueSnackbar({
        message: MESSAGES.approveFailed,
        options: { variant: "error" },
      })
    );
    throw new Error(error);
  }
}

export function* postLiquidity(
  {
    blxmTokensValue,
    externalTokensValue,
    lockDaysNumber,
    activeWallet,
    successCallback,
    failureCallback,
    provider,
  }:
    {
      blxmTokensValue: number,
      externalTokensValue: number,
      lockDaysNumber: number,
      activeWallet: any,
      successCallback: any,
      failureCallback: any,
      provider: any,
    }
) {

  const deadline = Date.now() + 1000 * 60;
  const { address: walletAddress, networkUrl } = activeWallet;

  const { liquidityRouterContract, blxmContract, pairTokenContract, tokenAddress, routerAddress } =
    ChainCode.getContracts(ChainCode.chainID);

  var blxmDecimals: number;
  var tokenDecimals: number;
  [blxmDecimals, tokenDecimals] = yield all([
    call(_getDecimals, { router: blxmContract }),
    call(_getDecimals, { router: pairTokenContract }),
  ]);

  let blxmTokensValueIntegerYiWan: number = Math.floor(blxmTokensValue * 10000)
  // @dev TODO test for decimals > 4
  let _blxmDecimals: number = Number(blxmDecimals);
  let _tokenDecimals: number = Number(tokenDecimals);
  let rest: number = _blxmDecimals - 4;
  if (rest >= 0) {
    _blxmDecimals -= 4;
  } else {
    blxmTokensValueIntegerYiWan = blxmTokensValueIntegerYiWan * (10 ** rest);
  }
  let blxmMultiplier: number = 10 ** _blxmDecimals;
  const blxmTokenValueBN: number = blxmTokensValueIntegerYiWan * blxmMultiplier;

  let externalTokenValueIntegerYiWan: number = Math.floor(externalTokensValue * 10000);
  rest = _tokenDecimals - 4;
  if (rest >= 0) {
    _tokenDecimals -= 4;
  } else {
    externalTokenValueIntegerYiWan = externalTokenValueIntegerYiWan * (10 ** rest);
  }
  // @dev TODO test for decimales > 4
  let tokenMultiplier: number = 10 ** _tokenDecimals;
  const externalTokenValueBN : number = externalTokenValueIntegerYiWan * tokenMultiplier;

  const blxmTokenValue: string = String(blxmTokenValueBN);
  const externalTokenValue: string = String(externalTokenValueBN);

  const minCofficientPermil: number = Math.floor(REACT_APP_MIN_COEFFICIENT * 1000);
  const _blxmTokensValueMinPermil: number = blxmTokenValueBN * minCofficientPermil;
  const blxmTokensValueMin : string = Number(Math.floor(Number(_blxmTokensValueMinPermil) / 1000)).toLocaleString('fullwide', {useGrouping:false});

  const _externalTokensValueMinPermil: number = externalTokenValueBN * minCofficientPermil;
  const externalTokensValueMin: string = Number(Math.floor(Number(_externalTokensValueMinPermil) / 1000)).toLocaleString('fullwide', {useGrouping:false});

  console.log("ADD LIQUIDITY: blxmTokensValueMin %s, externalTokensValueMin %s",blxmTokensValueMin,  externalTokensValueMin);

  try {
    const [blxmAllowed, externalAllowed] = yield all([
      call(_getAllowance, {
        walletAddress,
        contractAddress: routerAddress,
        contract: blxmContract,
      }),
      call(_getAllowance, {
        walletAddress,
        contractAddress: routerAddress,
        contract: pairTokenContract,
      }),
    ]);

    console.log("LIQUIDITY blxmAllowed is %s, externalAllowed is %s", blxmAllowed, externalAllowed);
    if (
      blxmAllowed < blxmTokenValueBN ||
      externalAllowed < externalTokenValueBN
    ) {
      yield call(_approveLiquidity, {
        blxmTokenValue: Number(blxmTokenValueBN).toLocaleString('fullwide', {useGrouping:false}), 
        externalTokenValue: Number(externalTokenValueBN).toLocaleString('fullwide', {useGrouping:false}),
        walletAddress,
        liquidityRouterContract,
        blxmContract,
        pairTokenContract,
      });
    }

    const response = yield call(_addLiquidity, {
      blxmTokenValue,
      externalTokenValue: Number(externalTokenValueBN).toLocaleString('fullwide', {useGrouping:false}),
      blxmTokensValueMin: Number(blxmTokensValueMin).toLocaleString('fullwide', {useGrouping:false}),
      externalTokensValueMin: Number(externalTokensValueMin).toLocaleString('fullwide', {useGrouping:false}),
      walletAddress,
      deadline,
      lockDaysNumber,
      liquidityRouterContract,
      tokenAddress,
    });

    yield call(api.post, `/liquidity/add`, {
      request: {
        tokenAddress,
        blxmTokenValue,
        externalTokenValue,
        blxmTokensValueMin,
        externalTokensValueMin,
        walletAddress,
        deadline,
        lockDaysNumber,
      },
      response,
    });

    const link = getTransactionUrl(networkUrl, response?.transactionHash);

    yield put(postLiquiditySuccess());
    successCallback(link);
  } catch (error) {
    yield put(postLiquidityFailure(error));

    if (error.code === 4001) {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionDeniedByUser,
          options: { variant: "error" },
        })
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionFailure,
          options: { variant: "warning" },
        })
      );
    }
    failureCallback();
  }
}

async function _retrieveLiquidity(
  {
    liquidity,
    blxmTokenValueMin,
    externalTokensValueMin,
    walletAddress,
    deadline,
    index,
    liquidityRouterContract,
  }:
    {
      liquidity: any,
      blxmTokenValueMin: any,
      externalTokensValueMin: any,
      walletAddress: any,
      deadline: any,
      index: any,
      liquidityRouterContract: any,
    }
) {
  console.log("REMOVE Liquidity liquidity %s index %s blxmTokenValueMin %s externalTokensValueMin %s",
    Number(liquidity).toLocaleString('fullwide', {useGrouping:false}),
    index,
    Number(blxmTokenValueMin).toLocaleString('fullwide', {useGrouping:false}),
    Number(externalTokensValueMin).toLocaleString('fullwide', {useGrouping:false})
  );
  let receipt = await liquidityRouterContract.removeLiquidity(
    Number(liquidity).toLocaleString('fullwide', {useGrouping:false}),
    Number(blxmTokenValueMin).toLocaleString('fullwide', {useGrouping:false}),
    Number(externalTokensValueMin).toLocaleString('fullwide', {useGrouping:false}),
    walletAddress,
    deadline,
    index
  );
  return await receipt.wait();
}

const bignumberTransform = (
  {
    blxmDecimals,
    tokenDecimals,
    ratio,
    liquidityGwei, // @dev IMPORTANT am passing in liquidity * gwei to avoid BigInt decimals issues
  }:
    {
      blxmDecimals: number,
      tokenDecimals: number,
      ratio: number,
      liquidityGwei: any,
    }
) => {
  /*
  const blxmDecimalsBN = BigNumber(`1e${Number(blxmDecimals)}`);
  const tokenDecimalsBN = BigNumber(`1e${Number(tokenDecimals)}`);
  const divisorBN = BigNumber("1e+36");
  const ratioBN = BigNumber(ratio);

  const tokenToBLXMRate = ratioBN
    .multipliedBy(blxmDecimalsBN.multipliedBy(tokenDecimalsBN))
    .dividedBy(divisorBN)
    .dividedBy("1e+18")
    .toString();

  const liquidityBN = BigNumber(liquidity);

  const tokenAmountBN = liquidityBN
    .multipliedBy(liquidityBN)
    .dividedBy(tokenToBLXMRate)
    .squareRoot();

  const blxmAmountBN = tokenAmountBN.multipliedBy(tokenToBLXMRate);
  */
  const gwei: number = 10 ** 9;
  const ether: bigint = BigInt(10) ** BigInt(18);
  const blxmDecimalsBN: bigint = BigInt(10) ** BigInt(blxmDecimals);
  const tokenDecimalsBN: bigint = BigInt(10) ** BigInt(tokenDecimals);
  const divisorBN: bigint = BigInt(10) ** BigInt(36);
  const divisorGweiBN: bigint = BigInt(10) ** BigInt(18); // @dev IMPORTANT reduce divisorBN by gwei*gwei because of multiplication of gwei factors in numerator of division
  const ratioBN: bigint = BigInt(ratio);

  const tokenToBLXMRate: bigint = (ratioBN * blxmDecimalsBN * tokenDecimalsBN) / (divisorBN * ether);
  const liquidityGweiBN: bigint = BigInt(liquidityGwei);
  const liquidityBN = liquidityGweiBN / BigInt(gwei); // @dev originally liquidityBN was BigInt(liquidity)
  // console.log("TRANSFORM liquidityGweiBN %s vs. liquidityBN %s", liquidityGweiBN, liquidityBN)
  let tokenAmountBN: bigint = sqrt((liquidityBN * liquidityBN) / tokenToBLXMRate);
  let tokenAmountGweiBN: bigint = sqrt(
    (liquidityGweiBN * liquidityGweiBN) 
    / (tokenToBLXMRate * BigInt(gwei) * BigInt(gwei))
  );
  // console.log("TRANSFORM tokenAmountGweiBN %s = tokenAmountBN %s", tokenAmountGweiBN, tokenAmountBN);
  tokenAmountBN = tokenAmountGweiBN;
  const blxmAmountBN: bigint = tokenAmountBN * tokenToBLXMRate;
  let _tokenAmount = ethers.formatUnits(tokenAmountBN.toString(), tokenDecimals);
  let _blxmAmount = ethers.formatUnits(blxmAmountBN.toString(), blxmDecimals);
  // console.log("tokenAmountBN = %s blxmAmountBN = %s", _tokenAmount, _blxmAmount)

  return { blxmAmountBN, tokenAmountBN, blxmDecimalsBN, tokenDecimalsBN };
};

export async function getExpectedTokens(
  { liquidity, activeWallet, provider }:
    { liquidity: any, activeWallet: any, provider: any }
) {
  try {
    const { address: walletAddress } = activeWallet;

    const { liquidityRouterContract, tokenAddress, blxmContract, pairTokenContract } =
      ChainCode.getContracts(ChainCode.chainID);

    const [blxmDecimals, tokenDecimals, ratio] = await Promise.all([
      _getDecimals({ router: blxmContract }),
      _getDecimals({ router: pairTokenContract }),
      _getRatio({
        walletAddress,
        liquidityRouterContract,
        tokenAddress,
      }),
    ]);
    console.log("RATIO : BLXM decimals %s, token decimals %s, RATIO %s", blxmDecimals, tokenDecimals, ratio);

    let liquidityGwei: number = liquidity * gwei;
    const { blxmAmountBN, tokenAmountBN } = bignumberTransform({
      blxmDecimals,
      tokenDecimals,
      ratio,
      liquidityGwei, // liquidity,
    });

    return {
      blxmAmountBN,
      tokenAmountBN,
    };
  } catch (error) {
    enqueueSnackbar({
      message: MESSAGES.calculateExpectedTokensFailed,
      options: { variant: "error" },
    });
    return null;
  }
}

export function* retrieveLiquidity(
  {
    liquidity,
    index,
    activeWallet,
    provider,
    retrieveLiquiditySuccessCallback,
  }:
    {
      liquidity: number,
      index: any,
      activeWallet: any,
      provider: any,
      retrieveLiquiditySuccessCallback: any,
    }
) {
  try {
    const deadline = Date.now() + 1000 * 60;
    const { address: walletAddress, networkUrl } = activeWallet;

    const { liquidityRouterContract, tokenAddress, blxmContract, pairTokenContract } = ChainCode.getContracts(ChainCode.chainID);

    const [blxmDecimals, tokenDecimals, ratio] = yield all([
      call(_getDecimals, { router: blxmContract }),
      call(_getDecimals, { router: pairTokenContract }),
      call(_getRatio, {
        walletAddress,
        liquidityRouterContract,
        tokenAddress,
      }),
    ]);

    const blxmDecimalsBN: number = 10 ** Number(blxmDecimals);
    const tokenDecimalsBN: number = 10 ** Number(tokenDecimals);

    let liquidityGwei: number = liquidity * gwei;
    const _liquidityForCalculations: number = (
      liquidityGwei * Math.sqrt(blxmDecimalsBN * tokenDecimalsBN) // @dev multiply instead of divide.divide
      )
      /
      blxmDecimalsBN
    ;

    const liquidityForCalculations: string = String(_liquidityForCalculations);
    console.log("LIQUIDITY - liquidity is %s liquidityForCalculation is %s", liquidity, liquidityForCalculations);

    const { blxmAmountBN, tokenAmountBN } = bignumberTransform({
      blxmDecimals,
      tokenDecimals,
      ratio,
      liquidityGwei: liquidityForCalculations,
    });

    const _blxmTokenValueMin: number = Number(blxmAmountBN) * REACT_APP_MIN_COEFFICIENT * Number(blxmDecimalsBN);
    const blxmTokenValueMin: string = String(_blxmTokenValueMin);

    const _externalTokensValueMin: number = Number(tokenAmountBN) * REACT_APP_MIN_COEFFICIENT * Number(tokenDecimalsBN);
    const externalTokensValueMin: string = String(_externalTokensValueMin);

    const _liquidityBNValue: number = Number(_liquidityForCalculations) * gwei;
    const liquidityBNValue: string = String(_liquidityBNValue);

    const response = yield call(_retrieveLiquidity, {
      liquidity: liquidityBNValue,
      blxmTokenValueMin,
      externalTokensValueMin,
      walletAddress,
      deadline,
      index,
      liquidityRouterContract,
    });

    yield call(api.post, `/liquidity/withdraw`, {
      request: {
        liquidity: liquidityBNValue,
        blxmTokenValueMin,
        externalTokensValueMin,
        walletAddress,
        deadline,
        index,
      },
      response,
    });

    const link = getTransactionUrl(networkUrl, response?.transactionHash);

    yield put(retrieveLiquiditySuccess(index));
    retrieveLiquiditySuccessCallback(link);
  } catch (error) {
    yield put(retrieveLiquidityFailure(error));

    if (error.code === 4001) {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionDeniedByUser,
          options: { variant: "error" },
        })
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionFailure,
          options: { variant: "warning" },
        })
      );
    }
  }
}

async function _getNominalAmounts(
  { treasuryContract }: { treasuryContract: any }
) {
  return await treasuryContract.get_nominal_amounts();
}

export function* getCalculationsData(
  { activeWallet, provider }: { activeWallet: any, provider: any }
) {
  const walletAddress = activeWallet?.address;
  const hourUnix = Math.round(Date.now() / (1000 * 60 * 60));

  const { liquidityRouterContract, blxmContract, pairTokenContract, tokenAddress, treasuryContract } =
    ChainCode.getContracts(ChainCode.chainID);

  try {
    const [ratio, session, nominalAmounts] = yield all([
      call(_getRatio, {
        walletAddress,
        liquidityRouterContract,
        tokenAddress,
      }),
      call(_hoursToSession, {
        walletAddress,
        liquidityRouterContract,
        tokenAddress,
        hourUnix,
      }),
      call(_getNominalAmounts, {
        treasuryContract,
        walletAddress,
      }),
    ]);

    const periods = yield call(_getPeriods, {
      walletAddress,
      liquidityRouterContract,
      tokenAddress,
      session,
    });

    var blxmDecimals: number;
    var tokenDecimals: number;
    [blxmDecimals, tokenDecimals] = yield all([
      call(_getDecimals, { router: blxmContract }),
      call(_getDecimals, { router: pairTokenContract }),
    ]);

    const totalLiquidity = {
      nominalAmountBlxm: bigNumberToNormal(
        nominalAmounts.amount0,
        blxmDecimals
      ),
      nominalAmountExternal: bigNumberToNormal(
        nominalAmounts.amount1,
        tokenDecimals
      ),
    };

    const ratioBN: number = Number(ratio);
    console.log("RATIO ratioBN=%s", ratioBN);
    const blxmDecimalsBN: number = 10 ** Number(blxmDecimals);
    const tokenDecimalsBN: number = 10 ** Number(tokenDecimals);
    console.log("tokenDecimals %s", tokenDecimals);
    const divisorBN: number = 10 ** 36;
    console.log("divisorBN %s", divisorBN);

    const rewardsPerHour = bigNumberToNormal(periods.amountPerHours);

    const _numerator : number = ratioBN * blxmDecimalsBN * tokenDecimalsBN;
    console.log("RATIO numerator is %s", _numerator);
    const _denominator : number = divisorBN * ether;
    console.log("RATIO _denominator is %s", _denominator);
    const _tokenToBLXMRate: number =
      (_numerator)
      /
      (_denominator);
    const tokenToBLXMRate: String = String(_tokenToBLXMRate);
    console.log("RATE tokenToBLXMRate is %s", tokenToBLXMRate);

    if (!rewardsPerHour || parseFloat(rewardsPerHour) === 0) {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.noRewards,
          options: { variant: "warning" },
        })
      );
    }

    yield put(
      getCalculationsDataSuccess(
        totalLiquidity,
        rewardsPerHour,
        tokenToBLXMRate,
        String(blxmDecimals),
        String(tokenDecimals)
      )
    );
  } catch (error) {
    yield put(getCalculationsDataFailure(error));
    yield put(
      enqueueSnackbar({
        message: MESSAGES.contractConnectError4,
        options: { variant: "error" },
      })
    );
  }
}

export function* liquiditySaga() {
  yield takeLeading(GET_LIQUIDITIES_REQUEST, getLiquidities);
  yield takeLeading(POST_LIQUIDITY_REQUEST, postLiquidity);
  yield takeLeading(RETRIEVE_LIQUIDITY_REQUEST, retrieveLiquidity);
  yield takeLeading(GET_CALCULATIONS_DATA_REQUEST, getCalculationsData);
}
