import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Button, Input, sharedStyles, Text } from "components/shared";
// import DotsLoader from "components/shared/dotsLoader";
import logo from "assets/logos/token.svg";
import USDCLogo from "assets/logos/USDC.png";
import { ROUTES } from "enums";
import LiquidityLayout from "layouts/LiquidityLayout";
import { MobileContext } from "context";
import styles from "./style.module.css";
var sqrt = require('bigint-isqrt');

const LOCK_OPTIONS = [
  { value: 30, factor: 1.1 },
  { value: 60, factor: 1.2 },
  { value: 90, factor: 1.3 },
];

const defaultValues = {
  blxmTokensValue: null,
  externalTokensValue: null,
  lockDaysNumber: null,
};

export const LiquidityHome = ({
  enqueueSnackbar,
  connectWallet,
  postLiquidity,
  activeWallet,
  getCalculationsData,
  totalLiquidity,
  rewardsPerHour,
  tokenToBLXMRate,
  provider,
  balance: { externalBalance, blxmBalance },
  refreshBalance,
  isUpdating,
}) => {
  const { register, handleSubmit, control, setValue, watch } = useForm({
    defaultValues,
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);

  const onLockOptionClick = (value) => {
    const newValue = value === watch("lockDaysNumber") ? null : value;

    if (newValue)
      enqueueSnackbar({
        message: `Your tokens are going to be locked for ${newValue} days`,
        options: { variant: "warning" },
      });
    setValue("lockDaysNumber", newValue);
  };

  const handleBlxmTokensChange = (event) => {
    const value = event.target.value.replace(/[^0-9_.]/g, "");

    setValue("blxmTokensValue", value, {
      shouldValidate: true,
    });
    setValue("externalTokensValue", value / tokenToBLXMRate, {
      shouldValidate: true,
    });
  };

  const handleExternalTokensChange = (event) => {
    const value = event.target.value.replace(/[^0-9_.]/g, "");

    setValue("externalTokensValue", value, {
      shouldValidate: true,
    });
    setValue("blxmTokensValue", value * tokenToBLXMRate, {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (provider) {
      getCalculationsData(activeWallet, provider);
    }
    if (activeWallet?.address) {
      refreshBalance();
    }
  }, [activeWallet, getCalculationsData, provider, refreshBalance]);

  useEffect(() => {
    if (activeWallet?.address) {
      refreshBalance();
    }
  }, [activeWallet?.address, refreshBalance]);

  const calculateAPR = ({ factor }) => {
    const blxmTokensValue : number | null =
      Number.isNaN(watch("blxmTokensValue")) ||
      watch("blxmTokensValue") == null ||
      !watch("blxmTokensValue")
        ? 0
        : watch("blxmTokensValue");

    const externalTokensValue : number | null =
      Number.isNaN(watch("externalTokensValue")) ||
      watch("externalTokensValue") == null ||
      !watch("externalTokensValue")
        ? 0
        : watch("externalTokensValue");
      // @dev normalize because we receive postDecimals --> *1000 
    const rewardsPerHourBigNumberPermil : number= Math.floor(rewardsPerHour * 1000);
    const nominalAmountBLXM : number = Number(totalLiquidity.nominalAmountBlxm);
    const nominalAmountExternal : number = Number(totalLiquidity.nominalAmountExternal);

    // console.log("totalLiquidity.nominalAmountBlxm %s, .nominalAmountExternal %s", nominalAmountBLXM, nominalAmountExternal);
    if (rewardsPerHour == 0) return "...";
    let blxm : number = (blxmTokensValue === null ? 0 : blxmTokensValue);
    let LIQUIDITY : number = Math.sqrt( 
      (nominalAmountBLXM + blxm) 
      *
      (nominalAmountExternal + ( externalTokensValue === null ? 0 : externalTokensValue))
    ); // @dev total "normalization" of 1.000.000 in this term
    // console.log("LIQUIDITY is %s", LIQUIDITY);
    const REWARD_PER_LIQUIDITY = rewardsPerHour / LIQUIDITY;

    let _apr : number = REWARD_PER_LIQUIDITY * 365 * 24 * 100 * 0.88 * factor;

    // console.log("_apr is %s", _apr);
    const APR : string = parseFloat(String(_apr)).toFixed(2);

  
    return Number.isNaN(APR) ? "..." : APR;
  };

  const setMaxValues = () => {
    const shouldUseBlxm =
      blxmBalance.balance / externalBalance.balance <= tokenToBLXMRate;

    setValue(
      "blxmTokensValue",
      shouldUseBlxm
        ? blxmBalance.balance
        : externalBalance.balance * tokenToBLXMRate,
      { shouldValidate: true }
    );
    setValue(
      "externalTokensValue",
      shouldUseBlxm
        ? blxmBalance.balance / tokenToBLXMRate
        : externalBalance.balance,
      {
        shouldValidate: true,
      }
    );
  };

  const successCallback = (url) => {
    setIsProcessing(false);

    if (url) {
      enqueueSnackbar({
        message: "Transaction confirmed.",
        options: {
          variant: "success",
          // eslint-disable-next-line react/no-unstable-nested-components
          action: () => (
            <a
              href={url}
              className={styles.transactionLink}
              target="_blank"
              rel="noreferrer"
            >
              Go to transaction
            </a>
          ),
        },
      });
    }

    navigate(ROUTES.INVESTMENT_MANAGEMENT);
  };

  const failureCallback = () => {
    setIsProcessing(false);
  };

  const provideLiquidity = ({
    blxmTokensValue,
    externalTokensValue,
    lockDaysNumber,
  }) => {
    setIsProcessing(true);
    postLiquidity(
      blxmTokensValue,
      externalTokensValue,
      lockDaysNumber ?? 0,
      activeWallet,
      successCallback,
      failureCallback,
      provider
    );
  };

  return (
    <LiquidityLayout>
      {isProcessing ? (
        <div className={sharedStyles.column}>
          <Text tag="p" type="normal">
            You are providing {watch("blxmTokensValue")} amount of BLXM tokens
            and {watch("externalTokensValue")} amount of USDC tokens.
          </Text>
          {watch("lockDaysNumber") && (
            <Text tag="p" type="normal">
              (locked for a period of {watch("lockDaysNumber")} days).
            </Text>
          )}
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(provideLiquidity)}
          className={sharedStyles.column}
        >
          <Input
            {...register("blxmTokensValue", {
              required: true,
            })}
            control={control}
            placeholder="Amount of BLXM"
            onChange={handleBlxmTokensChange}
            rightChild={
              <div className={styles.rightChild}>
                <Button
                  onClick={setMaxValues}
                  disabled={!activeWallet || isUpdating}
                  className={styles.inputButton}
                >
                  MAX
                </Button>
                <div className={sharedStyles.spacer} />
                <img src={logo} className={styles.inputLogo} alt="main-logo" />
              </div>
            }
          />
          <div className={sharedStyles.spacer} />
          <Input
            {...register("externalTokensValue", {
              required: true,
            })}
            placeholder="Amount of USDC"
            onChange={handleExternalTokensChange}
            rightChild={
              <div className={styles.rightChild}>
                <Button
                  onClick={setMaxValues}
                  disabled={!activeWallet || isUpdating}
                  className={styles.inputButton}
                >
                  MAX
                </Button>
                <div className={sharedStyles.spacer} />
                <div>
                  <img
                    src={USDCLogo}
                    className={styles.inputLogo}
                    alt="main-logo"
                  />
                </div>
              </div>
            }
          />
          <div className={sharedStyles.spacer} />
          <Text>APR {calculateAPR({ factor: 1 })}%</Text>
          <div className={styles.lockOptionsContainer}>
            <Text tag="p" type="normal">
              Locking options
            </Text>
            <div className={isMobile ? sharedStyles.column : sharedStyles.row}>
              {LOCK_OPTIONS.map((item) => (
                <div className={styles.lockOptionWrapper} key={item.value}>
                  <Button
                    onClick={() => onLockOptionClick(item.value)}
                    className={[
                      styles.button,
                      watch("lockDaysNumber") === item.value
                        ? styles.activeButton
                        : styles.inactiveButton,
                    ].join(" ")}
                  >
                    {`${item.value} days`}
                  </Button>
                  <Text>APR {calculateAPR({ factor: item.factor })}%</Text>
                </div>
              ))}
            </div>
          </div>
          <div className={sharedStyles.spacer} />
          {activeWallet ? (
            <Button type="submit" disabled={isUpdating}>
              Provide Liquidity
            </Button>
          ) : (
            <Button
              onClick={() =>
                connectWallet({
                  redirectSuccessUrl: ROUTES.LIQUIDITY,
                  redirectErrorUrl: ROUTES.LIQUIDITY,
                  validationSkip: true,
                })
              }
            >
              Connect Wallet
            </Button>
          )}
        </form>
      )}
    </LiquidityLayout>
  );
};

LiquidityHome.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  connectWallet: PropTypes.func.isRequired,
  postLiquidity: PropTypes.func.isRequired,
  getCalculationsData: PropTypes.func.isRequired,
  tokenToBLXMRate: PropTypes.string.isRequired,

  totalLiquidity: PropTypes.object,
  rewardsPerHour: PropTypes.string,
  activeWallet: PropTypes.object,
  balance: PropTypes.object,
  provider: PropTypes.object,
  refreshBalance: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
};

LiquidityHome.defaultProps = {
  totalLiquidity: {
    nominalAmountBlxm: "0",
    nominalAmountExternal: "0",
  },
  rewardsPerHour: "0",
  activeWallet: null,
  balance: {
    externalBalance: {
      balance: 0,
    },
    blxmBalance: {
      balance: 0,
    },
  },
  provider: {},
  isUpdating: false,
};
