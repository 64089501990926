import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getLiquiditiesRequest: ["activeWallet", "signer", "provider"], // GET_LIQUIDITIES_REQUEST
  getLiquiditiesSuccess: ["liquidities"], // GET_LIQUIDITIES_SUCCESS
  getLiquiditiesFailure: ["error"], // GET_LIQUIDITIES_FAILURE
  // POST_LIQUIDITY_REQUEST
  postLiquidityRequest: [
    "blxmTokensValue",
    "externalTokensValue",
    "lockDaysNumber",
    "activeWallet",
    "successCallback",
    "failureCallback",
    "provider",
  ],
  postLiquiditySuccess: [], // POST_LIQUIDITY_SUCCESS
  postLiquidityFailure: ["error"], // POST_LIQUIDITY_FAILURE
  // RETRIEVE_LIQUIDITY_REQUEST
  retrieveLiquidityRequest: [
    "liquidity",
    "index",
    "activeWallet",
    "provider",
    "retrieveLiquiditySuccessCallback",
  ],
  retrieveLiquiditySuccess: ["index"], // RETRIEVE_LIQUIDITY_SUCCESS
  retrieveLiquidityFailure: ["error"], // RETRIEVE_LIQUIDITY_FAILURE

  getCalculationsDataRequest: ["activeWallet", "provider"], // GET_CALCULATIONS_DATA_REQUEST
  // GET_CALCULATIONS_DATA_SUCCESS
  getCalculationsDataSuccess: [
    "totalLiquidity",
    "rewardsPerHour",
    "tokenToBLXMRate",
    "blxmDecimals",
    "tokenDecimals",
  ],
  getCalculationsDataFailure: ["error"], // GET_CALCULATIONS_DATA_FAILURE
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
