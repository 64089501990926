import React, { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, sharedStyles } from "components/shared";
import Text from "components/shared/text";
import { MobileContext } from "context";
import { COLORS, ROUTES } from "enums";
import walletActions from "store/wallet/actions";
import logo from "assets/logos/token.svg";
// import DotsLoader from "components/shared/dotsLoader";
import styles from "./style.module.css";
var sqrt = require('bigint-isqrt');

const DECIMALS = 100000;

const LiquidityLayout = ({
  children,
  address,
  activeWallet,
  userWallets,
  disconnectWallet,
  connectWallet,
  totalLiquidity,
  balance,
  rewardsPerHour,
  isUpdating,
}) => {
  const isMobile = useContext(MobileContext);

  const navigate = useNavigate();

  const navigateToMainPage = () => navigate(ROUTES.INITIAL);

  /*
  const nominalAmountBlxm = BigNumber(
    totalLiquidity.nominalAmountBlxm.toString()
  );
  const nominalAmountExternal = BigNumber(
    totalLiquidity.nominalAmountExternal.toString()
  );

  const LIQUIDITY = nominalAmountBlxm
    .multipliedBy(nominalAmountExternal)
    .squareRoot();
  */
  const nominalAmountBlxmPermil : number = Math.floor(totalLiquidity.nominalAmountBlxm * 1000);
    // @dev multiply by 1000 because of e.g. 123.45678
  const nominalAmountBlxm : bigint = BigInt(nominalAmountBlxmPermil);
  const nominalAmountExternalPermil : number = Math.floor(totalLiquidity.nominalAmountExternal * 1000);
    // @dev multiply by 1000 because of e.g. 123.45678
  const nominalAmountExternal : bigint = BigInt(nominalAmountExternalPermil);

 const LIQUIDITY : bigint = sqrt(nominalAmountBlxm * nominalAmountExternal) / BigInt(1000);
    // @dev retrieve sqrt(10^6) as 10^3 
  return (
    <div className={[sharedStyles.column, styles.container].join(" ")}>
      <div className={styles.wrapper}>
        {isMobile && (
          <div className={[sharedStyles.row, styles.mobileHeader].join(" ")}>
            <div onClick={navigateToMainPage}>
              <img
                src={logo}
                className={[styles.logo, styles.header].join(" ")}
                alt="main-logo"
              />
            </div>
            <Text tag="h1" type="big" className={styles.mobileHeaderText}>
              BLXM Provide Liquidity
            </Text>
          </div>
        )}
        <div className={isMobile ? sharedStyles.column : sharedStyles.row}>
          {activeWallet && (
            <>
              <div style={{ flex: 3 }}>
                {!!address && (
                  <div
                    className={
                      isMobile ? sharedStyles.column : sharedStyles.row
                    }
                  >
                    <Text
                      tag="p"
                      type="small"
                      style={
                        isMobile
                          ? { paddingTop: 16, paddingBottom: 8 }
                          : { paddingRight: 6, backgroundColor: COLORS.WHITE }
                      }
                    >
                      WALLET:
                    </Text>
                    <Text tag="p" type="small" className={styles.addressText}>
                      {address}
                    </Text>
                  </div>
                )}
                <div
                  style={{ justifyContent: "space-evenly" }}
                  className={isMobile ? sharedStyles.column : sharedStyles.row}
                >
                  {userWallets?.length > 1 && (
                    <Button
                      onClick={() => {
                        navigate(ROUTES.CLAIMING_WALLETS, {
                          redirectUrl: ROUTES.LIQUIDITY,
                        });
                      }}
                      type="link"
                    >
                      CHANGE CONTRACT
                    </Button>
                  )}
                  <Button onClick={disconnectWallet} type="link">
                    DISCONNECT
                  </Button>
                </div>
              </div>
              <div className={sharedStyles.spacer} />
            </>
          )}

          <div>
            <Button
              className={styles.manageButton}
              onClick={navigateToMainPage}
            >
              Back to main page
            </Button>
            <div className={sharedStyles.spacer} />
            <Button
              className={styles.manageButton}
              onClick={() => {
                if (!activeWallet) {
                  connectWallet({
                    redirectSuccessUrl: ROUTES.INVESTMENT_MANAGEMENT,
                    redirectErrorUrl: ROUTES.LIQUIDITY,
                    validationSkip: true,
                  });
                } else {
                  navigate(ROUTES.INVESTMENT_MANAGEMENT);
                }
              }}
            >
              MANAGE LIQUIDITY
            </Button>
          </div>
        </div>
        {!isMobile && (
          <div className={sharedStyles.column}>
            <div onClick={navigateToMainPage}>
              <img
                src={logo}
                className={[styles.logo, styles.header].join(" ")}
                alt="main-logo"
              />
            </div>
            <Text tag="h1" type="big">
              BLXM Provide Liquidity
            </Text>
          </div>
        )}
        <div className={sharedStyles.spacer} />
        <div className={isMobile ? sharedStyles.column : sharedStyles.row}>
          <div className={styles.flex}>
            <Text tag="h2" type="medium" className={styles.mobileHeaderText}>
              BLXM Liquidity rewards program
            </Text>
          </div>
          <div className={sharedStyles.spacer} />
          {balance?.externalBalance && balance?.blxmBalance ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isUpdating ? (
                <Text tag="p" type="normal" className={styles.text}>
                  Updating
                </Text>
              ) : (
                <div className={styles.flex}>
                  <Text type="normal">
                    Amount of {balance.blxmBalance.name}:{" "}
                    {Math.floor(
                      Number(balance.blxmBalance.balance) * DECIMALS
                    ) / DECIMALS}
                  </Text>
                  <Text type="normal">
                    Amount of {balance.externalBalance.name}:{" "}
                    {Math.floor(
                      Number(balance.externalBalance.balance) * DECIMALS
                    ) / DECIMALS}
                  </Text>
                  <Text type="normal">
                    Rewards per hour: {Number(rewardsPerHour).toFixed(5)}
                  </Text>
                  <Text type="normal">
                    Liquidity in the pool: {Number(LIQUIDITY).toFixed(5)}
                  </Text>
                </div>
              )}
            </>
          ) : (
            <div className={styles.flex}>
              <Text type="normal">Please connect your wallet to proceed</Text>
            </div>
          )}
          <div className={sharedStyles.spacer} />
        </div>
      </div>

      <div className={styles.childWrapper}>{children}</div>
    </div>
  );
};

LiquidityLayout.propTypes = {
  children: PropTypes.element.isRequired,
  address: PropTypes.string.isRequired,
  userWallets: PropTypes.array.isRequired,
  activeWallet: PropTypes.object,
  disconnectWallet: PropTypes.func.isRequired,
  connectWallet: PropTypes.func.isRequired,
  balance: PropTypes.object.isRequired,
  rewardsPerHour: PropTypes.string,
  totalLiquidity: PropTypes.object,
  isUpdating: PropTypes.bool,
};

LiquidityLayout.defaultProps = {
  activeWallet: null,
  rewardsPerHour: 0,
  totalLiquidity: {
    nominalAmountBlxm: "0",
    nominalAmountExternal: "0",
  },
  isUpdating: false,
};

const mapStateToProps = (state) => ({
  userWallets: state.wallet.userWallets,
  activeWallet: state.wallet.activeWallet,
  address: state.wallet.address,
  balance: state.wallet.balance,
  totalLiquidity: state.liquidity.totalLiquidity,
  rewardsPerHour: state.liquidity.rewardsPerHour,
  isUpdating: state.wallet.isLoading,
});

const mapDispatchToProps = {
  disconnectWallet: walletActions.Creators.disconnectWalletRequest,
  connectWallet: walletActions.Creators.connectWalletRequest,
};

export { LiquidityLayout as LiquidityLayoutComponent };
export default connect(mapStateToProps, mapDispatchToProps)(LiquidityLayout);
