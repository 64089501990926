// @ts-nocheck
import { ethers } from "ethers";
import { push } from "redux-first-history";
import { call, put, take, all, takeLeading } from "redux-saga/effects";
import Web3Modal from "web3modal";
import tokenAbiJson from "services/MockedTokens.json";
import api from "services/api";
import messageActions from "store/message/actions";
import { ROUTES, providerOptions, MESSAGES } from "enums";
import {
  REACT_APP_BSC_BLXM_ADDRESS,
  REACT_APP_BSC_PAIR_TOKEN_ADDRESS,
} from "config";
import { CHAIN_TYPES } from "enums/CHAIN_TYPES";
import Actions from "./actions";
import { ChainCode } from "../../web3/chainCode";


const {
  Creators: { enqueueSnackbar },
} = messageActions;

const web3Modal = new Web3Modal({
  cacheProvider: false,
  providerOptions
});
let provider: any;
let web3Provider: any;

const {
  Types: {
    CONNECT_WALLET_REQUEST,
    DISCONNECT_WALLET_REQUEST,
    SET_ACTIVE_WALLET_REQUEST,
    SET_ACTIVE_WALLET_SUCCESS,
    SET_USER_WALLETS_REQUEST,
    REFRESH_BALANCE_REQUEST,
  },
  Creators: {
    connectWalletSuccess,
    connectWalletFailure,

    disconnectWalletRequest,
    disconnectWalletSuccess,

    setUserWalletsRequest,
    setUserWalletsSuccess,
    setUserWalletsFailure,

    setActiveWalletRequest,
    setActiveWalletFailure,
    setActiveWalletSuccess,

    refreshBalanceRequest,
    refreshBalanceFailure,
    refreshBalanceSuccess,
  },
} = Actions;

function chainIdToHex(chainId: any) : Number {
  // Store the chain ID in hex string
  let chainIdInHex : Number = Number(chainId);

  if ( ( typeof chainId === "number" ) || ( typeof chainId === "bigint" )) {
    chainIdInHex = Number(chainId.toString(16));
    console.log("chainIdInHex is %s", chainIdInHex);
  } else {
    console.log(" typeof is %s", typeof chainId);
  }

  return chainIdInHex;
}

function* _getBalances({ address, tokenAddress } : { address: any, tokenAddress: any }) {
  try {
    const contract = new ethers.Contract(tokenAddress, tokenAbiJson.abi, web3Provider);
    // const contract = 


    const { balanceOf, name, symbol, decimals } = contract;

    const [balanceInWei, tokenName, tokenSymbol, tokenDecimals] = yield all([
      call(balanceOf, address),
      call(name),
      call(symbol),
      call(decimals),
    ]);

    let _balance : bigint = BigInt(balanceInWei);
    let _td : bigint = BigInt(tokenDecimals);
    let ten : bigint = BigInt(10);
    let multiplier : bigint = ten ** _td;

    let _ethers : bigint = BigInt(_balance / multiplier);
    const balance : string = String(_ethers);
    console.log("balance is %s ethers", balance);

    return { balance, name: tokenName, symbol: tokenSymbol };
  } catch (error: any) {
    throw new Error(error);
  }
}

function* getBalances({ address }) {
  
  return yield all([
    call(_getBalances, {
      address,
      tokenAddress: REACT_APP_BSC_BLXM_ADDRESS,
    }),
    call(_getBalances, {
      address,
      tokenAddress: REACT_APP_BSC_PAIR_TOKEN_ADDRESS,
    }),
  ]);
}

export function* _disconnectWallet() {
  yield put(disconnectWalletSuccess());
  yield put(
    enqueueSnackbar({
      message: MESSAGES.walletDisconnected,
      options: { variant: "info" },
    })
  );
}

function verifyWalletNetwork({ wallet } : { wallet: any }) {
  let incorrectNetwork = true;
  const chainId = ChainCode.chainID;
  console.log("verifying chainID = %s for wallet.chain_type %s", chainId, wallet.chain_type);
  if (
    (Number(chainId) == 56 && wallet.chain_type === "BSC") ||
    (Number(chainId) == 97 && wallet.chain_type === "BSC_TEST")
  ) {
    incorrectNetwork = false;
    console.log("will return %s", !incorrectNetwork);
  }

  if (incorrectNetwork) {
    enqueueSnackbar({
      message: MESSAGES.incorrectNetwork,
      options: { variant: "error" },
    });
  }

  return !incorrectNetwork;
}

export function* setWallet({
  activeWallet,
  redirectSuccessUrl,
  redirectErrorUrl,
} : {
  activeWallet: any,
  redirectSuccessUrl: any,
  redirectErrorUrl: any,
}) 
{
  try {
    const isWalletNetworksCorrect = verifyWalletNetwork({
      wallet: activeWallet,
    });
    console.log("set verify is %s", isWalletNetworksCorrect);

    if (!isWalletNetworksCorrect) {
      yield call(disconnectWalletRequest);
      yield put(push(redirectErrorUrl));
      return;
    }

    yield put(setActiveWalletSuccess(activeWallet));
    yield put(push(redirectSuccessUrl));
  } catch (error) {
    yield put(setActiveWalletFailure(error));
    yield put(
      enqueueSnackbar({
        message: MESSAGES.walletConnectError1,
        options: { variant: "error" },
      })
    );
  }
}

export function* setUserWallets({
  userWallets,
  redirectSuccessUrl,
  redirectErrorUrl,
} : {
  userWallets: any,
  redirectSuccessUrl: any,
  redirectErrorUrl: any,
}) {
  try {
    if (!userWallets?.length) {
      yield put(push(redirectErrorUrl));
      yield call(disconnectWalletRequest);
      return;
    }

    yield put(setUserWalletsSuccess(userWallets));

    if (userWallets?.length === 1) {
      yield put(
        setActiveWalletRequest(
          userWallets[0],
          redirectSuccessUrl,
          redirectErrorUrl
        )
      );
      return;
    }

    yield put(
      push(ROUTES.CLAIMING_WALLETS, {
        redirectUrl: redirectSuccessUrl,
      })
    );
  } catch (error) {
    yield put(setUserWalletsFailure(error));
    yield put(
      enqueueSnackbar({
        message: MESSAGES.walletConnectError2,
        options: { variant: "error" },
      })
    );
  }
}

function* verifyWalletAndProceed({
  address,
  chainIdInHex,

  redirectSuccessUrl,
  redirectErrorUrl,
} : {
  address: any,
  chainIdInHex: any,

  redirectSuccessUrl: any,
  redirectErrorUrl: any,
}) 
{
  console.log("verifying %s and %s", address, chainIdInHex);
  if (address && chainIdInHex) {
    const checksumAddress = ethers.getAddress(address);

    try {
      const { data: userWallets } = yield call(
        api.get,
        `/claiming/${checksumAddress}?chain_id=${chainIdInHex}`
      );
      yield put(
        setUserWalletsRequest(userWallets, redirectSuccessUrl, redirectErrorUrl)
      );
    } catch (error) {
      yield put(
        enqueueSnackbar({
          message: `We could not find your address in our database, please disconnect this
          wallet and connect with the address you gave us as receiving address.
          Make sure the network you are using is correct (BSC only).`,
          options: { variant: "error" },
        })
      );
      throw new Error();
    }
  }
}
async function _initWallet () {
  await ChainCode.initWallet();
}


/*****
 * 
 * @dev TODO Harry - somehow (
    via redux/action and saga - 
      onClick{() => connectWallet({...})
      } <- CONNECT_WALLET_REQUEST <- connectWallet: walletActions.Creators.connectWalletRequest 
      
    )  
 * 
 *****/
export function* _connectWallet({ routingOptions } : { routingOptions: any }) {
  let address : any;
  const validationSkip = routingOptions?.validationSkip ?? false;

  const { redirectSuccessUrl, redirectErrorUrl } = routingOptions;

  try {
    yield _initWallet();
    
    provider = yield call(web3Modal.connect);
    if ( provider )
      console.log("found web3Modal provider");
    else
      console.log("no web3Modal");

    let signer = ChainCode.signer;
    web3Provider = ChainCode.web3provider;
    let chainId = ChainCode.chainID;

    // Get the current address
    console.log("1");
    address = ChainCode.accounts[0];
    console.log("2");
    const chainIdInHex = chainIdToHex(chainId);

    if (provider) {
      if (!validationSkip) {
        yield call(verifyWalletAndProceed, {
          address,
          chainIdInHex,

          redirectSuccessUrl,
          redirectErrorUrl,
        });
      } else {
        
        const wallets = ChainCode.accounts;
        console.log("looking at wallet[0] is %s for chainID %d", wallets[0], chainId);
        const userWallets = wallets.map((wallet) => {
          // const chainType = CHAIN_TYPES.find((item) => item.id === chainId);
          const chainType = CHAIN_TYPES.find((item) =>  (ChainCode.getChain(item)).id == ChainCode.chainID );
          if (!chainType) {
            throw new Error("gettingLost");
          }
          const { name, url } = chainType;
          return {
            address: wallet,
            chain_type: name,
            networkUrl: url,
          };
        });
        yield put(
          setUserWalletsRequest(
            userWallets,
            redirectSuccessUrl,
            redirectErrorUrl
          )
        );
      }

      yield take(SET_ACTIVE_WALLET_SUCCESS);

      const [blxmBalance, externalBalance] = yield getBalances({
        address,
      });

      yield put(
        enqueueSnackbar({
          message: MESSAGES.walletConnected,
          options: { variant: "success" },
        })
      );

      yield put(
        connectWalletSuccess(address, signer, provider, validationSkip, {
          blxmBalance,
          externalBalance,
        })
      );
    }
  } catch (error: any) {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.incorrectNetwork,
          options: { variant: "error" },
        })
      );
    yield put(connectWalletFailure(error?.message));
  }
}

export function* refreshBalance() {
  try {
    yield call(refreshBalanceRequest);

    // Get the current address
    const signer = ChainCode.signer;
    const address = signer.address;
    console.log("refresh balance for signer %s", address);

    console.log("BEFORE");

    const [blxmBalance, externalBalance] = yield getBalances({
      address,
    });
    console.log("AFTER");

    yield put(
      refreshBalanceSuccess({
        blxmBalance,
        externalBalance,
      })
    );
  } catch (error) {
    
    yield put(refreshBalanceFailure(error?.message));
  }
}

export function* walletSaga() {
  
  yield takeLeading(CONNECT_WALLET_REQUEST, _connectWallet);
  yield takeLeading(DISCONNECT_WALLET_REQUEST, _disconnectWallet);
  yield takeLeading(SET_ACTIVE_WALLET_REQUEST, setWallet);
  yield takeLeading(SET_USER_WALLETS_REQUEST, setUserWallets);
  yield takeLeading(REFRESH_BALANCE_REQUEST, refreshBalance);
}
