import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getStakingRequest: ["activeWallet", "signer", "provider"], // GET_STAKING_REQUEST
  getStakingSuccess: ["staking"], // GET_STAKING_SUCCESS
  getStakingFailure: ["error"], // GET_STAKING_FAILURE
  // POST_STAKING_REQUEST
  postStakingRequest: [
    "blxmTokensValue",
    "lockDaysNumber",
    "activeWallet",
    "successCallback",
    "failureCallback",
    "provider",
  ],
  postStakingSuccess: [], // POST_STAKING_SUCCESS
  postStakingFailure: ["error"], // POST_STAKING_FAILURE
  // RETRIEVE_STAKING_REQUEST
  retrieveStakingRequest: [
    "amount",
    "index",
    "activeWallet",
    "provider",
    "retrieveStakingSuccessCallback",
  ],
  retrieveStakingSuccess: ["index"], // RETRIEVE_STAKING_SUCCESS
  retrieveStakingFailure: ["error"], // RETRIEVE_STAKING_FAILURE

  // RE_STAKE_REQUEST
  reStakeRequest: [
    "amount",
    "rewardAmount",
    "activeWallet",
    "index",
    "lockDays",
    "provider",
    "retrieveStakingSuccessCallback",
  ],
  reStakeSuccess: ["index"], // RE_STAKE_SUCCESS
  reStakeFailure: ["error"], // RE_STAKE_FAILURE

  // GET_CALCULATIONS_DATA_FOR_STAKING_REQUEST
  getCalculationsDataForStakingRequest: ["activeWallet", "provider", "signer"],
  // GET_CALCULATIONS_DATA_FOR_STAKING_SUCCESS
  getCalculationsDataForStakingSuccess: [
    "totalStaking",
    "rewardsPerHour",
    "APRs",
  ],
  getCalculationsDataForStakingFailure: ["error"], // GET_CALCULATIONS_DATA_FOR_STAKING_FAILURE
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
