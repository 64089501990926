export const MESSAGES = {
  walletConnected: "Wallet successfully connected",
  walletConnectError1: "Failed connecting the wallet-1",
  walletConnectError2: "Failed connecting the wallet-2",
  walletConnectError3: "Failed connecting the wallet-3",
  walletDisconnected:
    "Wallet disconnected. You can now disconnect the wallet using MetaMask",
  incorrectNetwork: "Trying to connect to wrong network",
  gettingLost: "lost & found",

  contractConnectError1: "Cannot retrieve investment packages-1",
  contractConnectError2: "Cannot retrieve investment packages-2",
  contractConnectError3: "Cannot retrieve investment packages-3",
  contractConnectError4: "Cannot retrieve investment packages-4",
  approveFailed: "Failed to approve spender in token contract",
  transactionDeniedByUser: "User denied transaction signature",
  calculateExpectedTokensFailed: "Failed to retrieve expected tokens",
  withdrawLockedTokens:
    "You are not going to receive any reward if you retrieve tokens before the locking period ends",

  noRewards:
    "We are not distributing rewards right now, please come back later",

  transactionPending: "Transaction is pending",
  transactionSuccess: "Transaction succeeded",
  transactionFailure: "Please check blockchain explorer (etherscan or bscscan)",
};
