import React, { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, sharedStyles } from "components/shared";
import Text from "components/shared/text";
import { MobileContext } from "context";
import { COLORS, ROUTES } from "enums";
import walletActions from "store/wallet/actions";
import logo from "assets/logos/token.svg";
// import DotsLoader from "components/shared/dotsLoader";
import styles from "./style.module.css";

const DECIMALS = 100000;

const StakingLayout = ({
  children,
  address,
  activeWallet,
  userWallets,
  disconnectWallet,
  connectWallet,
  balance,
  totalStaking,
  rewardsPerHour,
  isUpdating,
} : {
  children: any,
  address: any,
  activeWallet: any,
  userWallets: any,
  disconnectWallet: any,
  connectWallet: any,
  balance: any,
  totalStaking: any,
  rewardsPerHour: any,
  isUpdating: any,
}) => {
  const isMobile = useContext(MobileContext);

  const navigate = useNavigate();

  const navigateToMainPage = () => navigate(ROUTES.INITIAL);

  return (
    <div className={[sharedStyles.column, styles.container].join(" ")}>
      <div className={styles.wrapper}>
        {isMobile && (
          <div className={[sharedStyles.row, styles.mobileHeader].join(" ")}>
            <div onClick={navigateToMainPage}>
              <img
                src={logo}
                className={[styles.logo, styles.header].join(" ")}
                alt="main-logo"
              />
            </div>
            <Text tag="h1" type="big" className={styles.mobileHeaderText}>
            BLXM Staking
            </Text>
          </div>
        )}
        <div className={isMobile ? sharedStyles.column : sharedStyles.row}>
          {activeWallet && (
            <>
              <div style={{ flex: 3 }}>
                {!!address && (
                  <div
                    className={
                      isMobile ? sharedStyles.column : sharedStyles.row
                    }
                  >
                    <Text
                      tag="p"
                      type="small"
                      style={
                        isMobile
                          ? { paddingTop: 16, paddingBottom: 8 }
                          : { paddingRight: 6, backgroundColor: COLORS.WHITE }
                      }
                    >
                      WALLET:
                    </Text>
                    <Text tag="p" type="small" className={styles.addressText}>
                      {address}
                    </Text>
                  </div>
                )}
                <div
                  style={{ justifyContent: "space-evenly" }}
                  className={isMobile ? sharedStyles.column : sharedStyles.row}
                >
                  {userWallets?.length > 1 && (
                    <Button
                      onClick={() => {
                        navigate(ROUTES.CLAIMING_WALLETS, {
                          redirectUrl: ROUTES.STAKING,
                        });
                      }}
                      type="link"
                    >
                      CHANGE CONTRACT
                    </Button>
                  )}
                  <Button onClick={disconnectWallet} type="link">
                    DISCONNECT
                  </Button>
                </div>
              </div>
              <div className={sharedStyles.spacer} />
            </>
          )}

          <div>
            <Button
              className={styles.manageButton}
              onClick={navigateToMainPage}
            >
              Back to main page
            </Button>
              <div className={sharedStyles.spacer} />
            <Button
              className={styles.manageButton}
              onClick={() => {
                if (!activeWallet) {
                  connectWallet({
                    redirectSuccessUrl: ROUTES.INVESTMENT_MANAGEMENT,
                    redirectErrorUrl: ROUTES.STAKING,
                    validationSkip: true,
                  });
                } else {
                  navigate(ROUTES.INVESTMENT_MANAGEMENT);
                }
              }}
            >
              MANAGE STAKING
            </Button>
          </div>
        </div>
        {!isMobile && (
          <div className={sharedStyles.column}>
            <div onClick={navigateToMainPage}>
              <img
                src={logo}
                className={[styles.logo, styles.header].join(" ")}
                alt="main-logo"
              />
            </div>
            <Text tag="h1" type="big">
              BLXM Staking
            </Text>
          </div>
        )}
        <div className={sharedStyles.spacer} />
        <div className={isMobile ? sharedStyles.column : sharedStyles.row}>
          <div className={styles.flex}>
            <Text tag="h2" type="medium" className={styles.mobileHeaderText}>
              BLXM Staking rewards program
            </Text>
          </div>
          <div className={sharedStyles.spacer} />
          {balance?.externalBalance && balance?.blxmBalance ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isUpdating ? (
                <Text tag="p" type="normal" className={styles.text}>
                  Updating
                </Text>
              ) : (
                <div className={styles.flex}>
                  <Text type="normal">
                    Amount of {balance.blxmBalance.name}:{" "}
                    {Math.floor(
                      Number(balance.blxmBalance.balance) * DECIMALS
                    ) / DECIMALS}
                  </Text>
                  <Text type="normal">
                    Rewards per hour: {Number(rewardsPerHour).toFixed(5)}
                  </Text>
                  <Text type="normal">
                    Staking in the pool: {Number(totalStaking).toFixed(5)}
                  </Text>
                </div>
              )}
            </>
          ) : (
            <div className={styles.flex}>
              <Text type="normal">Please connect your wallet to proceed</Text>
            </div>
          )}
        </div>
      </div>

      <div className={styles.childWrapper}>{children}</div>
    </div>
  );
};

StakingLayout.propTypes = {
  children: PropTypes.element.isRequired,
  address: PropTypes.string.isRequired,
  userWallets: PropTypes.array.isRequired,
  activeWallet: PropTypes.object,
  disconnectWallet: PropTypes.func.isRequired,
  connectWallet: PropTypes.func.isRequired,
  balance: PropTypes.object.isRequired,
  totalStaking: PropTypes.string,
  rewardsPerHour: PropTypes.string,
  isUpdating: PropTypes.bool,
};

StakingLayout.defaultProps = {
  activeWallet: null,
  totalStaking: 0,
  rewardsPerHour: 0,
  isUpdating: false,
};

const mapStateToProps = (state: any) => ({
  userWallets: state.wallet.userWallets,
  activeWallet: state.wallet.activeWallet,
  address: state.wallet.address,
  balance: state.wallet.balance,
  totalStaking: state.staking.totalStaking,
  rewardsPerHour: state.staking.rewardsPerHour,
  isUpdating: state.wallet.isLoading,
});

const mapDispatchToProps = {
  disconnectWallet: walletActions.Creators.disconnectWalletRequest,
  connectWallet: walletActions.Creators.connectWalletRequest,
};

export { StakingLayout as StakingLayoutComponent };
export default connect(mapStateToProps, mapDispatchToProps)(StakingLayout);
