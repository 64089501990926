export const ROUTES = {
  INITIAL: "/",

  CLAIMING: "/claiming",
  CLAIMING_DASHBOARD: "/claiming/dashboard",
  CLAIMING_WALLETS: "/claiming/choose-wallet",
  CLAIMING_CONTRACT: "/claiming/read-contract",
  CLAIMING_TOKENS: "/claiming/claim-tokens",

  STAKING: "/staking",

  LIQUIDITY: "/liquidity",

  INVESTMENT_MANAGEMENT: "/investment-management",
  INTERNAL_ADMIN: "/internal-admin",

  NFTICKET: "/nfticket/:id",
};
