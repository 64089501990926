import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "enums";
import { Button, Text, sharedStyles } from "components/shared";
import { MobileContext } from "context";
import ClaimingLayout from "layouts/ClaimingLayout";
import styles from "./style.module.css";

export const MainPage = () => {
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);
  console.log('Welcome BLXM Investor Portal');
  return (
    <ClaimingLayout>
      <div className={isMobile ? sharedStyles.column : sharedStyles.row}>
        <div className={sharedStyles.column}>
          <Button onClick={() => navigate(ROUTES.LIQUIDITY)} type="rounded">
            Liquidity Module
          </Button>
          <div className={sharedStyles.spacer} />
          <Button onClick={() => navigate(ROUTES.STAKING)} type="rounded">
            Staking Module
          </Button>
          <div className={sharedStyles.spacer} />
        </div>
        <div className={sharedStyles.spacer} />
        <div className={sharedStyles.column}>
          <Text tag="p" type="normal" className={styles.marketingInfo}>
            We&apos;re excited to present our BLXM Investor Portal. Here, you
            can participate in our staking and liquidity program and manage and
            claim your tokens (if you participated in one of our token sales).
          </Text>
        </div>
      </div>
    </ClaimingLayout>
  );
};
