import { createReducer } from "reduxsauce";
import actions from "./actions";

const { Types } = actions;
const INITIAL_STATE = {
  cliffRemainingDays: 0,
  vestingRemainingDays: 0,
  contract: {},
  walletTokens: {},
  isLoading: false,
  error: null,
};

const request = (state) => ({
  ...state,
  isLoading: true,
  error: null,
});

const failure = (state, { error }) => ({
  ...state,
  isLoading: false,
  error,
});

const readContractSuccess = (
  state,
  { walletTokens, cliffRemainingDays, vestingRemainingDays, contract }
) => ({
  ...state,
  cliffRemainingDays,
  vestingRemainingDays,
  walletTokens,
  contract,
  isLoading: false,
});

export const HANDLERS = {
  [Types.READ_CONTRACT_REQUEST]: request,
  [Types.READ_CONTRACT_SUCCESS]: readContractSuccess,
  [Types.READ_CONTRACT_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
