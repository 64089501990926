export const NODE_ENV = process.env.NODE_ENV || "";
export const REACT_APP_ENV_LABEL = process.env.REACT_APP_ENV_LABEL || "";
export const REACT_APP_API_URI = process.env.REACT_APP_API_URI || "";
export const REACT_APP_INFURA_ID = process.env.REACT_APP_INFURA_ID || "";
export const REACT_APP_BSC_LSC_ADDRESS =
  process.env.REACT_APP_BSC_LSC_ADDRESS || "";
export const REACT_APP_BSC_BLXM_ADDRESS =
  process.env.REACT_APP_BSC_BLXM_ADDRESS || "";
export const REACT_APP_BSC_PAIR_TOKEN_ADDRESS =
  process.env.REACT_APP_BSC_PAIR_TOKEN_ADDRESS || "";
export const REACT_APP_BSC_API_URL = process.env.REACT_APP_BSC_API_URL || "";
export const REACT_APP_BSC_API_KEY = process.env.REACT_APP_BSC_API_KEY || "";
export const REACT_APP_BSC_TSC_ADDRESS =
  process.env.REACT_APP_BSC_TSC_ADDRESS || "";

export const REACT_APP_BSC_SSC_ADDRESS =
  process.env.REACT_APP_BSC_SSC_ADDRESS || "";

export const REACT_APP_MIN_COEFFICIENT =
  process.env.REACT_APP_MIN_COEFFICIENT || "";

export const REACT_APP_APR_REFERENCE_WALLET_ADDRESS =
  process.env.REACT_APP_APR_REFERENCE_WALLET_ADDRESS || "";
