import { ethers, formatUnits } from "ethers";

export const tokenFormatter = (value: any, commifyConvention = "US") => {
  if (!value) {
    return "0";
  }

  const unit = 18;

  const wei = BigInt(value);
  const result = formatUnits(wei, unit);

  /*
   * Format an amount of wei into a decimal string representing the amount of ether.
   * Takes an unit argument that defaults to 18.
   * Usage:
   *   value | ether:unit
   * Example:
   *   {{ 50000000000000000000 | ether }}
   *   formats to: 50
   */

  const formattedEtherValue = parseFloat(result)
    .toFixed(3)
    .slice(0, -1)
    .replace(/\.0+$/, "");

  const separator = commifyConvention === "US" ? "," : ".";
  const point = commifyConvention === "US" ? "." : ",";
  const parts = formattedEtherValue.split(".");

  /*
   * Format a string of number into a decimal string with dot and comma.
   * Takes an convention argument that either 'US' or 'EU' that defaults to 'EU'.
   * Usage:
   *   value | commify:convention
   * Example:
   *   {{ 12345678.90 | commify:'EU' }}
   *   formats to: 12.345.678,90
   */

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return parts.join(point);
};

export const bigNumberToNormal = (value: any, unit = 18) => {
  const stringValue = value.toString();

  const wei = BigInt(stringValue);
  return formatUnits(wei, unit);
};
