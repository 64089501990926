import { spawn } from "redux-saga/effects";
import { walletSaga } from "./wallet/sagas";
import { contractSaga } from "./contract/sagas";
import { liquiditySaga } from "./liquidity/sagas";
import { stakingSaga } from "./staking/sagas";

function* sagas() {
  yield spawn(walletSaga);
  yield spawn(contractSaga);
  yield spawn(liquiditySaga);
  yield spawn(stakingSaga);
}

export default sagas;
