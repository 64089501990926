import { createReducer } from "reduxsauce";
import actions from "./actions";

const { Types } = actions;
const INITIAL_STATE = {
  liquidities: [],
  isLoading: false,
  error: null,
  totalLiquidity: {
    nominalAmountBlxm: "0",
    nominalAmountExternal: "0",
  },
  blxmDecimals: "18",
  tokenDecimals: "18",
  rewardsPerHour: "0",
  tokenToBLXMRate: "4",
};

const request = (state) => ({
  ...state,
  isLoading: true,
  error: null,
});

const failure = (state, { error }) => ({
  ...state,
  isLoading: false,
  error,
});

const getLiquiditiesSuccess = (state, { liquidities }) => ({
  ...state,
  liquidities,
  isLoading: false,
});

const postLiquiditySuccess = (state, { liquidities }) => ({
  ...state,
  liquidities,
  isLoading: false,
});

const retrieveLiquiditySuccess = (state) => ({
  ...state,
  isLoading: false,
});

const getCalculationsDataSuccess = (
  state,
  {
    totalLiquidity,
    rewardsPerHour,
    tokenToBLXMRate,
    blxmDecimals,
    tokenDecimals,
  }
) => ({
  ...state,
  totalLiquidity,
  rewardsPerHour,
  tokenToBLXMRate,
  blxmDecimals,
  tokenDecimals,
  isLoading: false,
});

export const HANDLERS = {
  [Types.GET_LIQUIDITIES_REQUEST]: request,
  [Types.GET_LIQUIDITIES_SUCCESS]: getLiquiditiesSuccess,
  [Types.GET_LIQUIDITIES_FAILURE]: failure,

  [Types.POST_LIQUIDITY_REQUEST]: request,
  [Types.POST_LIQUIDITY_SUCCESS]: postLiquiditySuccess,
  [Types.POST_LIQUIDITY_FAILURE]: failure,

  [Types.RETRIEVE_LIQUIDITY_REQUEST]: request,
  [Types.RETRIEVE_LIQUIDITY_SUCCESS]: retrieveLiquiditySuccess,
  [Types.RETRIEVE_LIQUIDITY_FAILURE]: failure,

  [Types.GET_CALCULATIONS_DATA_REQUEST]: request,
  [Types.GET_CALCULATIONS_DATA_SUCCESS]: getCalculationsDataSuccess,
  [Types.GET_CALCULATIONS_DATA_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
