// @ts-nocheck
import { call, put, takeLeading, all } from "redux-saga/effects";
import { ethers } from "ethers";
import messageActions from "store/message/actions";
import {
  REACT_APP_BSC_BLXM_ADDRESS,
  REACT_APP_BSC_SSC_ADDRESS,
  REACT_APP_APR_REFERENCE_WALLET_ADDRESS,
} from "config";
import stakerContractJson from "services/BLXMStaker.json";
import tokenAbiJson from "services/MockedTokens.json";
// import { CHAIN_TYPES } from "enums/CHAIN_TYPES";
import { STAKING_REF_INDEXES } from "enums/STAKING_REF_PACKAGES";
import { bigNumberToNormal } from "services/tokenFormatter";
import calculateLockingDays from "services/calculateLockingDays";
import getTransactionUrl from "services/getTransactionUrl";
import { MESSAGES } from "enums";
import Actions from "./actions";
import { ChainCode } from "../../web3/chainCode";


const {
  Creators: { enqueueSnackbar },
} = messageActions;

const ether : number = 10 ** 18;

const {
  Types: {
    GET_STAKING_REQUEST,
    POST_STAKING_REQUEST,
    RETRIEVE_STAKING_REQUEST,
    RE_STAKE_REQUEST,
    GET_CALCULATIONS_DATA_FOR_STAKING_REQUEST,
  },
  Creators: {
    getStakingSuccess,
    getStakingFailure,
    postStakingSuccess,
    postStakingFailure,
    retrieveStakingSuccess,
    reStakeFailure,
    reStakeSuccess,
    retrieveStakingFailure,
    getCalculationsDataForStakingSuccess,
    getCalculationsDataForStakingFailure,
  },
} = Actions;

async function _initWallet () {
  await ChainCode.initWallet();
  let chainId = (await ChainCode.web3provider.getNetwork()).chainId;

  return [ChainCode.signer, ChainCode.web3provider, chainId];
}

const _getContracts = () => {

  // SSC Proxy
  const proxyAddress = REACT_APP_BSC_SSC_ADDRESS;
  const router = new ethers.Contract(proxyAddress, stakerContractJson.abi, ChainCode.signer);

  // Mocked blxm
  const blxmAddress = REACT_APP_BSC_BLXM_ADDRESS;
  const blxm = new ethers.Contract(blxmAddress, tokenAbiJson.abi, ChainCode.signer);

  return { router, blxm, proxyAddress, blxmAddress };
};


async function _getAllPositionLength({ walletAddress, router }) {
  console.log("getting staking for wallet %s", walletAddress);

  let length = await router.allPositionLength(walletAddress);
  console.log("found %s positions for %s", length, walletAddress);
  return length;
}

async function _getStakingPackage({ walletAddress, index, router }) {

  // returns(uint amount, uint extraAmount, uint32 startHour, uint32 endLocking);
  let { amount, extraAmount, startHour, endLocking } = await router.allPosition(walletAddress, index);
  return { amount, extraAmount, startHour, endLocking };
}

async function _getStakingEstimatedRewards({ walletAddress, index, router }) {
  let { rewardAmount, isLocked } = await router.calcRewards.staticCall(walletAddress, index);
  return { rewardAmount, isLocked };
}


export function* getStaking({ activeWallet, signer, provider }) {
  const { address: walletAddress } = activeWallet;
  _initWallet();
  signer = ChainCode.signer;
  provider = ChainCode.web3provider;
  // window.ethereum.enable();

  const { router, blxm, proxyAddress } = _getContracts();

  const routerContract = new ethers.Contract(proxyAddress, stakerContractJson.abi, signer);
  try {
    const stakingLength = yield call(_getAllPositionLength, {
      walletAddress,
      router,
    });

    const stakingArray = Array(parseInt(stakingLength, 10)).fill({});

    const response = yield all(
      stakingArray.map((_stake, index) =>
        all([
          call(_getStakingPackage, { walletAddress, index, router }),
          call(_getStakingEstimatedRewards, {
            walletAddress,
            index,
            router: routerContract,
          }),
        ])
      )
    );

    const flatResponse = response.map((item: any, index: any) => {
      const [lockingDaysLeft, isLocked, isDays] = calculateLockingDays(
        Number(item[0].startHour),
        Number(item[0].endLocking)
      );

      return {
        ...item[0],
        ...item[1],
        position: index,
        lockingDaysLeft,
        isLocked,
        isDays,
      };
    });

    const staking = flatResponse.filter((item: any) => item.amount !== "0");

    yield put(getStakingSuccess(staking));
  } catch (error) {
    yield put(getStakingFailure(error));
    yield put(
      enqueueSnackbar({
        message: MESSAGES.contractConnectError1,
        options: { variant: "error" },
      })
    );
  }
}

async function _addStaking(
  {
    blxmTokenValue,
    walletAddress,
    lockDaysNumber,
    router,
  } :
  {
    blxmTokenValue: number,
    walletAddress: any,
    lockDaysNumber: any,
    router: any,
  }
) 
{
  let receipt = await router.stake(
    Number(blxmTokenValue).toLocaleString('fullwide', {useGrouping:false}),
    walletAddress, 
    lockDaysNumber
  );
  return await receipt.wait();
  // return router.methods.stake(blxmTokenValue, walletAddress, lockDaysNumber).send({ from: walletAddress });
}

async function _approve(
  { 
    provider, 
    address, 
    tokensValue, 
    walletAddress 
  } :
  { 
    provider: any, 
    address: any, 
    tokensValue: any, 
    walletAddress: any 
  }
) 
{
  let tx = await provider.approve(address, tokensValue);
  let receipt = await tx.wait();
  console.log("approve events", receipt.logs);
  return;
  // return provider.methods.approve(address, tokensValue).send({ from: walletAddress });
}

function* _approveStaking(
  { blxmTokenValue, walletAddress, router, blxm} :
  { blxmTokenValue: number, walletAddress: any, router: any, blxm: any }
) 
{
  //, router, blxm }) {
  // _initWallet();
  // const { router, blxm, proxyAddress , blxmAddress } = _getContracts();
  
  console.log("APPROVE tokensValue in fullwide format is %s", Number(blxmTokenValue).toLocaleString('fullwide', {useGrouping:false}));
  try {
    yield call(_approve, {
      provider: blxm,
      address: router.getAddress(),
      tokensValue: Number(blxmTokenValue).toLocaleString('fullwide', {useGrouping:false}),
      walletAddress,
    });
  } catch (error) {
    yield put(postStakingFailure(error));
    yield put(
      enqueueSnackbar({
        message: MESSAGES.approveFailed,
        options: { variant: "error" },
      })
    );
    throw new Error(error);
  }
}

async function _getAllowance(
  { walletAddress, contractAddress, contract } :
  { walletAddress: any, contractAddress: any, contract: any }
) 
{
  return await contract.allowance(walletAddress, contractAddress);
}

export function* postStaking(
  {
    blxmTokensValue,
    lockDaysNumber,
    activeWallet,
    successCallback,
    failureCallback,
    provider,
  } : 
  {
    blxmTokensValue: any,
    lockDaysNumber: any,
    activeWallet: any,
    successCallback: any,
    failureCallback: any,
    provider: any,
  }
) 
{

  const blxmTokenValueBN : number = Number(blxmTokensValue) * ether;
  const blxmTokenValue : string = String(blxmTokenValueBN);
  console.log("postSTAKING %s BLXM", blxmTokenValue);

  const { address: walletAddress, networkUrl } = activeWallet;

  provider = ChainCode.web3provider;
  const { router, blxm, proxyAddress , blxmAddress } = _getContracts();


  try {
    let blxmAllowed = yield call(_getAllowance, 
      {
        walletAddress,
        contractAddress: proxyAddress,
        contract: blxm,
      } 
    );

    console.log("postSTAKING blxmAllowed = %s blxmTokenValue = %s", blxmAllowed, Number(blxmTokenValue));
    if ( Number(blxmAllowed) < Number(blxmTokenValue) ) {
      yield call(_approveStaking, {
        blxmTokenValue, // : MAX_ALLOWED_VALUE,
        walletAddress,
        router,
        blxm,
      });
    }
    
    const response = yield call(_addStaking, {
      blxmTokenValue,
      walletAddress,
      lockDaysNumber,
      router,
    });

    const link = getTransactionUrl(networkUrl, response?.transactionHash);

    yield put(postStakingSuccess());
    successCallback(link);
  } catch (error) {
    yield put(postStakingFailure(error));

    if (error.code === 4001) {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionDeniedByUser,
          options: { variant: "error" },
        })
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionFailure,
          options: { variant: "warning" },
        })
      );
    }
    failureCallback();
  }
}

async function _hoursToSession(
  { walletAddress, router, hourUnix } :
  {walletAddress: any, router: any, hourUnix: any }
) 
{
  return await router.hoursToSession(hourUnix);
  // return router.methods.hoursToSession(hourUnix).call({ from: walletAddress });
}

async function _getPeriods(
  { walletAddress, router, session } :
  { walletAddress: any, router: any, session: any }
) 
{
  return await router.getPeriods(session);
}

async function _getTreasuryFields(
  { walletAddress, router } :
  { walletAddress: any, router: any }
) 
{
  return await router.getTreasuryFields();
  // return router.methods.getTreasuryFields().call({ from: walletAddress });
}

export function* getCalculationsDataForStaking(
  { activeWallet, provider, signer } :
  { activeWallet: any, provider: any, signer: any }
) 
{
  let APRs = [];
  const walletAddress = activeWallet?.address;
  const hourUnix = Date.now() / (1000 * 60 * 60);

  const { router, blxm, proxyAddress } = _getContracts();
  const routerContract = new ethers.Contract(proxyAddress, stakerContractJson.abi, signer);

  try {
    const stakingLength = yield call(_getAllPositionLength, {
      walletAddress: REACT_APP_APR_REFERENCE_WALLET_ADDRESS,
      router,
    });

    const stakingArray = Array(parseInt(stakingLength, 10)).fill({});

    const response = yield all(
      stakingArray.map((_stake, index) =>
        all([
          call(_getStakingPackage, {
            walletAddress: REACT_APP_APR_REFERENCE_WALLET_ADDRESS,
            index,
            router,
          }),
          call(_getStakingEstimatedRewards, {
            walletAddress: REACT_APP_APR_REFERENCE_WALLET_ADDRESS,
            index,
            router: routerContract,
          }),
        ])
      )
    );

    const flatResponse = response.map((item: any) => ({
      ...item[0],
      ...item[1],
    }));

    const {
      THIRTY_DAYS_INDEX,
      NINETY_DAYS_INDEX,
      HALF_A_YEAR_INDEX,
      YEAR_INDEX,
    } = STAKING_REF_INDEXES;

    const referencePackages = [
      flatResponse[THIRTY_DAYS_INDEX],
      flatResponse[NINETY_DAYS_INDEX],
      flatResponse[HALF_A_YEAR_INDEX],
      flatResponse[YEAR_INDEX],
    ];

    let _hourUnix : bigint = BigInt(Math.floor(hourUnix));

    APRs = referencePackages.map((item) =>
      ( Number(item["amount"]) == 0 ? 0 :
        Number
        (
          Number(item["rewardAmount"]))
            /
          (Number(item["amount"])  * (Number(_hourUnix) - Number(item["startHour"]))
        )
        *
        (24 * 360 * 100 * 0.95)
      ).toFixed(2)
    );

    for ( var i = 0; i < APRs.length ; i++) {
      console.log("APR[%d] = %s", i, APRs[i]);
    }
    const treasuryFields = yield call(_getTreasuryFields, {
      walletAddress,
      router,
    });

    const totalStaking = bigNumberToNormal(treasuryFields.totalAmount);

    const session = yield call(_hoursToSession, {
      walletAddress,
      router,
      hourUnix: Math.round(hourUnix),
    });

    const periods = yield call(_getPeriods, {
      walletAddress,
      router,
      session,
    });

    const rewardsPerHour = bigNumberToNormal(periods.amountPerHours);

    yield put(
      getCalculationsDataForStakingSuccess(totalStaking, rewardsPerHour, APRs)
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: MESSAGES.contractConnectError2,
        options: { variant: "error" },
      })
    );
    yield put(getCalculationsDataForStakingFailure(error));
  }
}

async function _retrieveStaking(
  { 
    amount, 
    walletAddress, 
    index, 
    router 
  } :
  { 
    amount: any, 
    walletAddress: any, 
    index: any, 
    router: any 
  }
) 
{
  let receipt = await router.withdraw( 
    Number(amount).toLocaleString('fullwide', {useGrouping:false}),
    walletAddress, 
    index 
  );
  return await receipt.wait();
}

export function* retrieveStaking(
  {
    amount,
    index,
    activeWallet,
    provider,
    retrieveStakingSuccessCallback,
  } :
  {
    amount: any,
    index: any,
    activeWallet: any,
    provider: any,
    retrieveStakingSuccessCallback: any,
  }
) 
{
  try {
    const { address: walletAddress, networkUrl } = activeWallet;
    const { router } = _getContracts();

  
      let _amountBN : number = Number(amount) * ether;
      const amountBN : string = String(_amountBN);

    const response = yield call(_retrieveStaking, {
      amount: amountBN,
      walletAddress,
      index,
      router,
    });

    const link = getTransactionUrl(networkUrl, response?.transactionHash);

    yield put(retrieveStakingSuccess(index));
    retrieveStakingSuccessCallback(link);
  } catch (error) {
    yield put(retrieveStakingFailure(error));
    if (error.code === 4001) {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionDeniedByUser,
          options: { variant: "error" },
        })
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionFailure,
          options: { variant: "warning" },
        })
      );
    }
  }
}

export function* reStake({
  amount,
  rewardAmount,
  activeWallet,
  index,
  lockDays,
  provider,
  retrieveStakingSuccessCallback,
} : {
  amount: any,
  rewardAmount: any,
  activeWallet: any,
  index: any,
  lockDays: any,
  provider: any,
  retrieveStakingSuccessCallback: any,
}) {
  const { address: walletAddress, networkUrl } = activeWallet;
  const { router } = _getContracts();

  try {
    let _amount : bigint = BigInt(amount);
    let _rewardAmount : bigint = BigInt(rewardAmount);
    let _value : bigint = _amount + _rewardAmount;
    console.log("reSTAKE amount = %s, rewardAmount = %s, value = %s", _amount, _rewardAmount, _value);
    const value = _value.toString();

    yield call(_retrieveStaking, {
      amount,
      walletAddress,
      index,
      router,
    });

    const stakeResponse = yield call(_addStaking, {
      blxmTokenValue: value,
      walletAddress,
      lockDaysNumber: lockDays,
      router,
    });

    const link = getTransactionUrl(networkUrl, stakeResponse?.transactionHash);

    yield put(reStakeSuccess());
    retrieveStakingSuccessCallback(link);
  } catch (error) {
    yield put(reStakeFailure(error));
    if (error.code === 4001) {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionDeniedByUser,
          options: { variant: "error" },
        })
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: MESSAGES.transactionFailure,
          options: { variant: "warning" },
        })
      );
    }
  }
}

export function* stakingSaga() {
  yield takeLeading(GET_STAKING_REQUEST, getStaking);
  yield takeLeading(POST_STAKING_REQUEST, postStaking);
  yield takeLeading(
    GET_CALCULATIONS_DATA_FOR_STAKING_REQUEST,
    getCalculationsDataForStaking
  );
  yield takeLeading(RETRIEVE_STAKING_REQUEST, retrieveStaking);
  yield takeLeading(RE_STAKE_REQUEST, reStake);
}
