import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  readContractRequest: ["activeWallet", "signer"], // READ_CONTRACT_REQUEST
  // READ_CONTRACT_SUCCESS
  readContractSuccess: [
    "walletTokens",
    "cliffRemainingDays",
    "vestingRemainingDays",
    "contract",
  ],
  readContractFailure: ["error"], // READ_CONTRACT_FAILURE
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
