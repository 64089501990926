import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { logger } from "redux-logger";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import { NODE_ENV } from "config";
import rootReducer from "./reducer";
import rootSaga from "./saga";

const initialState = {};

const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const middleWare =
  NODE_ENV !== "production"
    ? [logger, routerMiddleware, sagaMiddleware]
    : [routerMiddleware, sagaMiddleware];

const composeEnhancers =
  NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export const store = createStore(
  combineReducers({ ...rootReducer, router: routerReducer }),
  initialState,
  composeEnhancers(applyMiddleware(...middleWare))
);

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
