/**
 * Calculates lockingDaysLeft and isLocked.
 * @param {string} startHour - returns the number of hours elapsed since January 1, 1970 00:00:00 UTC to start locking date.
 * @param {string} endLocking - returns the number of hours elapsed since January 1, 1970 00:00:00 UTC to end locking date.
 * @return {Array<number} [lockingDaysLeft, isLocked].
 */
const calculateLockingDays = (startHour: bigint, endLocking: bigint) => {
  let _startHour = Number(startHour);
  let _endLocking = Number(endLocking);
  const currentHourEpoch = Math.floor(Date.now() / (60 * 60 * 1000));
  const adjustedStartHour =
    currentHourEpoch > _startHour ? currentHourEpoch : _startHour;

  const isDays = Math.floor(_endLocking - adjustedStartHour) / 24 >= 1;
  const lockingDaysLeft = isDays
    ? Math.floor((_endLocking - adjustedStartHour) / 24)
    : _endLocking - adjustedStartHour;
  const isLocked = _endLocking - adjustedStartHour > 0;

  return [lockingDaysLeft, isLocked, isDays];
};

export default calculateLockingDays;
