import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import logo from "assets/logos/token.svg";
import { Button, Input, Text, sharedStyles } from "components/shared";
// import DotsLoader from "components/shared/dotsLoader";
import StakingLayout from "layouts/StakingLayout";
import { ROUTES, LOCK_OPTIONS } from "enums";
import styles from "./style.module.css";

const defaultValues = {
  blxmTokensValue: null,
  lockDaysNumber: 90,
};

export const Staking = ({
  enqueueSnackbar,
  connectWallet,
  postStaking,
  activeWallet,
  getCalculationsDataForStaking,
  balance: { blxmBalance },
  provider,
  refreshBalance,
  isUpdating,
  signer,
  APRs,
} : {
  enqueueSnackbar: any,
  connectWallet: any,
  postStaking: any,
  activeWallet: any,
  getCalculationsDataForStaking: any,
  balance: { blxmBalance: any },
  provider: any,
  refreshBalance: any,
  isUpdating: any,
  signer: any,
  APRs: any,
}
  ) => {
  const { register, handleSubmit, control, setValue, watch } = useForm({
    defaultValues,
  });

  const [isProcessing, setIsProcessing] = useState(false);
  const [chosenPeriodIndex, setChosenPeriodIndex] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    if (provider && signer)
      getCalculationsDataForStaking(activeWallet, provider, signer);
  }, [activeWallet, getCalculationsDataForStaking, provider, signer]);

  useEffect(() => {
    if (activeWallet?.address) {
      refreshBalance();
    }
  }, [activeWallet?.address, refreshBalance]);

  const onLockOptionClick = (value: any, index: any) => {
    if (value !== watch("lockDaysNumber")) {
      enqueueSnackbar({
        message: `Your tokens are going to be locked for ${value} days`,
        options: { variant: "warning" },
      });
      setValue("lockDaysNumber", value);
      setChosenPeriodIndex(index);
    }
  };

  const handleBlxmTokensChange = (event: any) => {
    const value = event.target.value.replace(/[^0-9_.]/g, "");

    setValue("blxmTokensValue", value, {
      shouldValidate: true,
    });
  };

  const setMaxValue = () => {
    setValue("blxmTokensValue", blxmBalance.balance, {
      shouldValidate: true,
    });
  };

  const successCallback = (url: any) => {
    setIsProcessing(false);

    if (url) {
      enqueueSnackbar({
        message: "Transaction confirmed.",
        options: {
          variant: "success",
          // eslint-disable-next-line react/no-unstable-nested-components
          action: () => (
            <a
              href={url}
              className={styles.transactionLink}
              target="_blank"
              rel="noreferrer"
            >
              Go to transaction
            </a>
          ),
        },
      });
    }

    navigate(ROUTES.INVESTMENT_MANAGEMENT);
  };

  const failureCallback = () => {
    setIsProcessing(false);
  };

  const provideStaking = (
    { blxmTokensValue, lockDaysNumber }
  ) => {
    setIsProcessing(true);
    postStaking(
      blxmTokensValue,
      lockDaysNumber ?? 0,
      activeWallet,
      successCallback,
      failureCallback,
      provider
    );
  };

  return (
    <StakingLayout>
      {isProcessing ? (
        <div className={sharedStyles.column}>
          <Text tag="p" type="normal">
            You are providing {watch("blxmTokensValue")} amount of BLXM tokens.
          </Text>
          {watch("lockDaysNumber") && (
            <Text tag="p" type="normal">
              (locked for a period of {watch("lockDaysNumber")} days).
            </Text>
          )}
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(provideStaking)}
          className={sharedStyles.column}
        >
          <Input
            {...register("blxmTokensValue", {
              required: true,
            })}
            control={control}
            placeholder="Amount of BLXM"
            onChange={handleBlxmTokensChange}
            rightChild={
              <div className={styles.rightChild}>
                <Button
                  onClick={setMaxValue}
                  disabled={!activeWallet || isUpdating}
                  className={styles.inputButton}
                >
                  Max
                </Button>
                <div className={sharedStyles.spacer} />
                <img src={logo} className={styles.inputLogo} alt="main-logo" />
              </div>
            }
          />
          <div className={sharedStyles.spacer} />
          <Text className={styles.boldText}>
            APR {APRs[chosenPeriodIndex]}%
          </Text>
          <div className={styles.lockOptionsContainer}>
            <Text tag="p" type="normal">
              Locking options
            </Text>
            <div className={sharedStyles.column}>
              {LOCK_OPTIONS.map((item, index) => (
                <div
                  className={[
                    styles.lockOptionWrapper,
                    sharedStyles.column,
                  ].join(" ")}
                  key={item.value}
                >
                  <Button
                    onClick={() => onLockOptionClick(item.value, index)}
                    className={[
                      styles.button,
                      watch("lockDaysNumber") === item.value
                        ? styles.activeButton
                        : styles.inactiveButton,
                    ].join(" ")}
                  >
                    {item.value} days
                  </Button>
                  <Text>APR {APRs[index]}%</Text>
                </div>
              ))}
            </div>
          </div>
          {activeWallet ? (
            <Button type="submit" disabled={isUpdating}>
              Provide Staking
            </Button>
          ) : (
            <Button
              onClick={() =>
                connectWallet({
                  redirectSuccessUrl: ROUTES.STAKING,
                  redirectErrorUrl: ROUTES.STAKING,
                  validationSkip: true,
                })
              }
            >
              Connect Wallet
            </Button>
          )}
        </form>
      )}
    </StakingLayout>
  );
};

Staking.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  connectWallet: PropTypes.func.isRequired,
  postStaking: PropTypes.func.isRequired,
  getCalculationsDataForStaking: PropTypes.func.isRequired,

  activeWallet: PropTypes.object,
  balance: PropTypes.object,
  provider: PropTypes.object,
  refreshBalance: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  signer: PropTypes.object.isRequired,
  APRs: PropTypes.array,
};

Staking.defaultProps = {
  activeWallet: null,
  balance: {
    blxmBalance: {
      balance: 0,
    },
  },
  provider: {},
  isUpdating: false,
  APRs: ["...", "...", "...", "..."],
};
