import { createReducer } from "reduxsauce";
import actions from "./actions";

const { Types } = actions;

const INITIAL_STATE = {
  staking: [],
  isLoading: false,
  error: null,
  totalStaking: "0",
  rewardsPerHour: "0",
  APRs: ["...", "...", "...", "..."],
};

const request = (state) => ({
  ...state,
  isLoading: true,
  error: null,
});

const failure = (state, { error }) => ({
  ...state,
  isLoading: false,
  error,
});

const success = (state) => ({
  ...state,
  isLoading: false,
});

const getStakingSuccess = (state, { staking }) => ({
  ...state,
  staking,
  isLoading: false,
});

const getCalculationsDataForStakingSuccess = (
  state,
  { totalStaking, rewardsPerHour, APRs }
) => ({
  ...state,
  totalStaking,
  rewardsPerHour,
  APRs,
  isLoading: false,
});

export const HANDLERS = {
  [Types.GET_STAKING_REQUEST]: request,
  [Types.GET_STAKING_SUCCESS]: getStakingSuccess,
  [Types.GET_STAKING_FAILURE]: failure,

  [Types.POST_STAKING_REQUEST]: request,
  [Types.POST_STAKING_SUCCESS]: success,
  [Types.POST_STAKING_FAILURE]: failure,

  [Types.RETRIEVE_STAKING_REQUEST]: request,
  [Types.RETRIEVE_STAKING_SUCCESS]: success,
  [Types.RETRIEVE_STAKING_FAILURE]: failure,

  [Types.RE_STAKE_REQUEST]: request,
  [Types.RE_STAKE_SUCCESS]: success,
  [Types.RE_STAKE_FAILURE]: failure,

  [Types.GET_CALCULATIONS_DATA_FOR_STAKING_REQUEST]: request,
  [Types.GET_CALCULATIONS_DATA_FOR_STAKING_SUCCESS]:
    getCalculationsDataForStakingSuccess,
  [Types.GET_CALCULATIONS_DATA_FOR_STAKING_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
