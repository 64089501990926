import { connect } from "react-redux";
import { LiquidityHome } from "components/Liquidity";
import walletActions from "store/wallet/actions";
import liquiditiesActions from "store/liquidity/actions";
import messageActions from "store/message/actions";

const mapStateToProps = (state) => ({
  error: state.wallet.error,
  activeWallet: state.wallet.activeWallet,
  totalLiquidity: state.liquidity.totalLiquidity,
  rewardsPerHour: state.liquidity.rewardsPerHour,
  balance: state.wallet.balance,
  tokenToBLXMRate: state.liquidity.tokenToBLXMRate,
  provider: state.wallet.provider,
  isUpdating: state.wallet.isLoading,
});

const mapDispatchToProps = {
  connectWallet: walletActions.Creators.connectWalletRequest,
  getCalculationsData: liquiditiesActions.Creators.getCalculationsDataRequest,
  postLiquidity: liquiditiesActions.Creators.postLiquidityRequest,
  enqueueSnackbar: messageActions.Creators.enqueueSnackbar,
  refreshBalance: walletActions.Creators.refreshBalanceRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquidityHome);
