import WalletConnectProvider from "@walletconnect/ethereum-provider";
import { REACT_APP_INFURA_ID } from "config";

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        1: `https://mainnet.infura.io/v3/${REACT_APP_INFURA_ID}`,
        4: `https://rinkeby.infura.io/v3/${REACT_APP_INFURA_ID}`,
        56: "https://bsc-dataseed.binance.org/",
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      },
    },
  },
};
