import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.css";

const Input = forwardRef(
  (
    { width, height, invalid, leftChild, rightChild, className, ...props },
    ref
  ) => (
    <div className={styles.wrapper} style={{ width }}>
      {leftChild}
      <input
        style={{ width, height }}
        ref={ref}
        className={`${styles.input} ${invalid && styles.invalid} ${
          styles.padding
        } ${className}`}
        {...props}
      />
      <div className={styles.rightChild}>{rightChild}</div>
    </div>
  )
);

Input.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  invalid: PropTypes.bool,
  className: PropTypes.string,
};

Input.defaultProps = {
  width: "100%",
  height: "50px",
  invalid: false,
  className: "",
};

Input.propTypes = {
  width: PropTypes.string,
  leftChild: PropTypes.element,
  rightChild: PropTypes.element,
};

Input.defaultProps = {
  width: "100%",
  leftChild: null,
  rightChild: null,
};

export default Input;
