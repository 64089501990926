/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";
import Text from "../text";
import styles from "./style.module.css";

const styleTypes = {
  text: styles.text,
  tertiary: styles.tertiary,
};

const Button = ({
  type,
  styleType,
  color,
  children,
  width,
  className,
  isRequesting,
  loaderStyles,
  ...props
}) => {
  const classNames = `${styles.base} ${styleTypes[styleType]} ${styles[color]} ${className}`;

  if (type === "link") {
    return (
      <button type={type} className={styles.link} style={{ width }} {...props}>
        <Text tag="p" type="small">
          {children}
        </Text>
      </button>
    );
  }
  return (
    <button type={type} className={classNames} style={{ width }} {...props}>
      <div className={styles.content}>
        {isRequesting && (
          <div className={styles.ldsRing} style={loaderStyles}>
            <div />
          </div>
        )}
        <div>{children}</div>
      </div>
    </button>
  );
};

Button.propTypes = {
  styleType: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  isRequesting: PropTypes.bool,
  loaderStyles: PropTypes.object,
};

Button.defaultProps = {
  styleType: "",
  color: "",
  children: "",
  type: "button",
  width: "",
  className: "",
  isRequesting: false,
  loaderStyles: {},
};

export default Button;
