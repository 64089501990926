import { connect } from "react-redux";
import { InvestorsArea } from "../components/InvestorsArea";
import liquiditiesActions from "../store/liquidity/actions";
import messageActions from "../store/message/actions";
import stakingActions from "../store/staking/actions";

const mapStateToProps = (state) => ({
  error: state.wallet.error,
  activeWallet: state.wallet.activeWallet,
  liquidities: state.liquidity.liquidities,
  blxmDecimals: state.liquidity.blxmDecimals,
  tokenDecimals: state.liquidity.tokenDecimals,
  provider: state.wallet.provider,
  signer: state.wallet.signer,
  staking: state.staking.staking,
  APRs: state.staking.APRs,
  isLiquidityLoading: state.liquidity.isLoading,
  isStakingLoading: state.staking.isLoading,
});

const mapDispatchToProps = {
  getCalculationsData: liquiditiesActions.Creators.getCalculationsDataRequest,
  getCalculationsDataForStaking:
    stakingActions.Creators.getCalculationsDataForStakingRequest,
  getLiquidities: liquiditiesActions.Creators.getLiquiditiesRequest,
  getStaking: stakingActions.Creators.getStakingRequest,
  retrieveLiquidity: liquiditiesActions.Creators.retrieveLiquidityRequest,
  retrieveStaking: stakingActions.Creators.retrieveStakingRequest,
  reStake: stakingActions.Creators.reStakeRequest,
  enqueueSnackbar: messageActions.Creators.enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorsArea);
