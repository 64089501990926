import message from "./message/reducers";
import wallet from "./wallet/reducers";
import contract from "./contract/reducers";
import liquidity from "./liquidity/reducers";
import staking from "./staking/reducers";

const reducers = {
  message,
  wallet,
  contract,
  liquidity,
  staking,
};

export default reducers;
